import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_STUDENT,
  DELETE_STUDENT,
  GET_STUDENT,
  GET_STUDENT_DETAILS,
  UPDATE_STUDENT,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET STUDENT LIST
export const getStudentList =
  (year, gradeId, shiftId, batchId, roll) => async (dispatch) => {
    try {
      const data = {
        year,
        batchId,
        gradeId,
        shiftId,
        year,
        uid: roll,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${BASE_URL}/api/student/filter`,
        JSON.stringify(data),
        config
      );

      dispatch({
        type: GET_STUDENT,
        payload: res.data.data,
      });

      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };
//GET STUDENT Details
export const getStudentDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/student/${id}`);

    dispatch({
      type: GET_STUDENT_DETAILS,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE STUDENT
export const createStudent = (values, image) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const data = new FormData();

  for (const [key, value] of Object.entries(values)) {
    data.append(key, value);
  }

  if (image) {
    data.append("image", image);
  }
  try {
    // TODO ::: API CALL
    const res = await axios.post(`${BASE_URL}/api/student`, data, config);
    dispatch({
      type: CREATE_STUDENT,
    });
    toast.success("Student added successfully");

    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE STUDENT
export const updateStudent = (values, id, image) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const data = new FormData();

  for (const [key, value] of Object.entries(values)) {
    data.append(key, value);
  }

  if (image) {
    data.append("image", image);
  }
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/student/${id}`,
      data,
      config
    );
    dispatch({
      type: UPDATE_STUDENT,
    });

    toast.success("Student updated successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Student
export const deleteStudent = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/student/${id}`);
    dispatch({
      type: DELETE_STUDENT,
      payload: id,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
