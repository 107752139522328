export const getGrade = (mark) => {
  mark = parseFloat(mark);

  if (mark >= 90.995) {
    return "A++";
  } else if (mark >= 80.995) {
    return "A+";
  } else if (mark >= 70.995) {
    return "A";
  } else if (mark >= 60.995) {
    return "A-";
  } else if (mark >= 50.995) {
    return "B";
  } else {
    return "C";
  }
};
