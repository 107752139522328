import {
  GET_ACTIVITY,
  GET_ACTIVITY_DETAILS,
  GET_ALL_ACTIVITY,
} from "../constants/Type";

const initialState = {
  activity: null,
  selected_activity: null,
  grade: null,
  loading: true,
};

const activityReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ACTIVITY:
      return {
        ...state,
        activity: payload,
        loading: false,
      };
    case GET_ALL_ACTIVITY:
      return {
        ...state,
        grade: payload,
        loading: false,
      };
    case GET_ACTIVITY_DETAILS:
      return {
        ...state,
        selected_activity: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default activityReducer;
