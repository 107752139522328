import { combineReducers } from "redux";
import activityReducer from "./Activity.reducer";
import authReducer from "./Auth.reducer";
import batchReducer from "./Batch.reducer";
import gradeReducer from "./Grade.reducer";
import markReducer from "./Mark.reducer";
import savedReducer from "./Saved.reducer";
import shiftReducer from "./Shift.reducer";
import studentReducer from "./Student.reducer";
import subjectReducer from "./Subject.reducer";

const reducer = combineReducers({
  auth: authReducer,
  grade: gradeReducer,
  shift: shiftReducer,
  subject: subjectReducer,
  batch: batchReducer,
  student: studentReducer,
  mark: markReducer,
  activity: activityReducer,
  saved: savedReducer,
});

export default reducer;
