import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getShiftList } from "../../actions/Shift.action";
import { AddShiftForm } from "../../components/AddShiftForm";
import Layout from "../../components/shared/Layout/Layout";

const AddShiftPage = ({ edit, shifts, getShiftList }) => {
  const { id } = useParams();
  useEffect(() => {
    if (edit && id && !shifts) {
      getShiftList();
    }
  }, [id]);
  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} Shift`}>
        {edit && id && shifts ? (
          <AddShiftForm
            edit={true}
            data={shifts.filter((item) => item.id === parseInt(id))[0]}
          />
        ) : (
          <AddShiftForm />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shifts: state.shift.shift,
});

export default connect(mapStateToProps, { getShiftList })(AddShiftPage);
