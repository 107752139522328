import React, { useEffect, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./SubjectList.module.css";
import { connect } from "react-redux";
import { AiFillEdit } from "react-icons/ai";
import { BsFileEarmarkPlus, BsTrash } from "react-icons/bs";
import { useModals } from "@mantine/modals";
import { Text } from "@mantine/core";
import { toast } from "react-toastify";
import { getGradeList } from "../../actions/Grade.action";
import { deleteSubject } from "../../actions/Subject.action";

const SubjectList = ({ list, getGradeList, deleteSubject }) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);

  const modals = useModals();

  useEffect(() => {
    if (list === null) {
      getGradeList();
    }
    if (list !== null) {
      setRawList(list);
    }
  }, [list]);

  const deleteHandeler = (id) =>
    modals.openConfirmModal({
      title: "Delete this subject",
      centered: true,
      children: (
        <Text size="md">
          Are you sure you want to delete this subject? This action is
          destructive and you can not undo it.
        </Text>
      ),
      labels: { confirm: "Delete Subject", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => confirmHandeler(id),
    });

  const confirmHandeler = async (id) => {
    let check = await deleteSubject(id);
    if (check === true) {
      toast.success("Subject deleted successfully");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <Container className="pb-4">
      <div className="d-flex justify-content-end align-items-center pb-3 ">
        <div className="text-right">
          <Link to="/admin/subject/add" className={styles.add}>
            <BsFileEarmarkPlus />
          </Link>
        </div>
      </div>
      <Card className="crd p-md-4 pb-md-0 p-2">
        <Card.Body>
          {rawList === null ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: 600 }}
            >
              <Spinner variant="dark" animation="grow" />
            </div>
          ) : rawList.length === 0 ? (
            <span className="text-center fs-4 d-block pb-3">
              No Subject Found
            </span>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Class</th>
                    <th>Subject</th>
                    <th>
                      <div className="d-flex justify-content-center align-items-center">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rawList !== null &&
                    rawList.map((gradeItem, i) => (
                      <tr key={gradeItem.id}>
                        <td>{i + 1}</td>
                        <td>{gradeItem.name}</td>
                        <td colSpan={2}>
                          <Table borderless responsive className="p-0 m-0">
                            <tbody>
                              {gradeItem.subjects.map((sft, i) => (
                                <tr
                                  key={sft.id}
                                  className={
                                    i !== gradeItem.subjects.length - 1
                                      ? "border-bottom"
                                      : ""
                                  }
                                >
                                  <td>{sft.name}</td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <Link
                                        to={`/admin/subject/${sft.id}/edit`}
                                        className={` fw-bold  me-4 ${styles.link}`}
                                      >
                                        <AiFillEdit />
                                      </Link>{" "}
                                      <span
                                        onClick={() => deleteHandeler(sft.id)}
                                        className={`fw-bold ${styles.link}`}
                                      >
                                        <BsTrash />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList, deleteSubject })(
  SubjectList
);
