export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ACCESS_TOKEN_ERROR = "ACCESS_TOKEN_ERROR";
export const ACCESS_TOKEN_SUCCESS = "ACCESS_TOKEN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const DASHBOARD_DATA = "DASHBOARD_DATA";

//PROFILE REDUCER
export const AUTH_USER_LOAD = "AUTH_USER_LOAD";
export const AUTH_USER_LOAD_ERROR = "AUTH_USER_LOAD_ERROR";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR";

// GRADE REDUCER
export const CREATE_GRADE = "CREATE_GRADE";
export const DELETE_GRADE = "DELETE_GRADE";
export const GET_GRADE_DETAILS = "GET_GRADE_DETAILS";
export const UPDATE_GRADE = "UPDATE_GRADE";
export const GET_GRADE = "GET_GRADE";

// SAVED
export const GET_SAVED = "GET_SAVED";
export const UPDATE_SAVED = "UPDATE_SAVED";

// SHIFT REDUCER
export const CREATE_SHIFT = "CREATE_SHIFT";
export const DELETE_SHIFT = "DELETE_SHIFT";
export const GET_SHIFT_DETAILS = "GET_SHIFT_DETAILS";
export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const GET_SHIFT = "GET_SHIFT";

// SUBJECT REDUCER
export const CREATE_SUBJECT = "CREATE_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const GET_SUBJECT_DETAILS = "GET_SUBJECT_DETAILS";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const GET_SUBJECT = "GET_SUBJECT";

// ACTIVITY REDUCER
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const GET_ACTIVITY_DETAILS = "GET_ACTIVITY_DETAILS";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ALL_ACTIVITY = "GET_ALL_ACTIVITY";

// BATCH REDUCER
export const CREATE_BATCH = "CREATE_BATCH";
export const DELETE_BATCH = "DELETE_BATCH";
export const GET_BATCH_DETAILS = "GET_BATCH_DETAILS";
export const GET_GRADE_BATCH = "GET_GRADE_BATCH";
export const UPDATE_BATCH = "UPDATE_BATCH";
export const GET_BATCH = "GET_BATCH";

// STUDENT REDUCER
export const CREATE_STUDENT = "CREATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const GET_STUDENT = "GET_STUDENT";

// MARK REDUCER
export const CREATE_MARK = "CREATE_MARK";
export const DELETE_MARK = "DELETE_MARK";
export const GET_MARK_DETAILS = "GET_MARK_DETAILS";
export const UPDATE_MARK = "UPDATE_MARK";
export const GET_MARK = "GET_MARK";
export const GET_STUDENT_MARK = "GET_STUDENT_MARK";
export const GET_STUDENT_MARK_UID = "GET_STUDENT_MARK_UID";
export const GET_BATCH_STUDENTS = "GET_BATCH_STUDENTS";

// SEMISTER REDUCER
export const CREATE_SEMISTER = "CREATE_SEMISTER";
export const DELETE_SEMISTER = "DELETE_SEMISTER";
export const GET_SEMISTER_DETAILS = "GET_SEMISTER_DETAILS";
export const UPDATE_SEMISTER = "UPDATE_SEMISTER";
export const GET_SEMISTER = "GET_SEMISTER";
export const GET_FULL_SEMISTER = "GET_FULL_SEMISTER";
export const GET_FULL_SEMISTER_TOP = "GET_FULL_SEMISTER_TOP";
export const GET_STUDENT_SEMISTER = "GET_STUDENT_SEMISTER";
export const GET_TOP_SEMISTER = "GET_TOP_SEMISTER";
export const GET_STUDENT_TERM_RESULT = "GET_STUDENT_TERM_RESULT";
