import React, { Fragment, useEffect } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { getActivityList } from "../../actions/Activity.action";

const ActivitiesList = ({ data, getActivityList }) => {
  useEffect(() => {
    if (!data) {
      getActivityList();
    }
  }, []);
  return (
    <div className="d-flex align-items-center flex-wrap py-2">
      {data ? (
        <>
          {data.length > 0 ? (
            data.map((act) => (
              <Fragment key={act.id}>
                <Form.Check
                  label={act.name}
                  id={`disabled-default-${act.id}`}
                  className="me-3"
                />
              </Fragment>
            ))
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.activity.activity,
});

export default connect(mapStateToProps, { getActivityList })(ActivitiesList);
