import { Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import React, { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { BsCheckAll } from "react-icons/bs";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteResult, updateSemister } from "../../../actions/Semister.action";
import styles from "../EditSemisterForm.module.css";

const OneResultRow = ({
  result,
  deleteResult,
  updateSemister,
  elRef,
  showToast,
}) => {
  const [mark, setMark] = useState(result.mark);
  const [icon, setIcon] = useState(true);
  const modals = useModals();
  const deleteHandeler = () =>
    modals.openConfirmModal({
      title: "Delete this result",
      centered: true,
      children: (
        <Text size="md">
          Are you sure you want to delete this result entry? This action is
          destructive and you can not undo it. <br />
          <b>Student Name: {result.student.name}</b> <br />
          <b>Student Roll: {result.student.uid}</b> <br />
        </Text>
      ),
      labels: { confirm: "Delete Result", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => confirmHandeler(result.id),
    });

  const confirmHandeler = async (id) => {
    let check = await deleteResult(id);
    if (check === true) {
      toast.success("Result deleted successfully");

      return;
    } else {
      return;
    }
  };

  const editHandeler = async () => {
    if (icon === true) {
      return;
    }
    let check = await updateSemister(mark, result.id);
    if (check === true) {
      setIcon(true);
      if (showToast) {
        toast.success("Result updated successfully");
      }
      return;
    } else {
      return;
    }
  };
  return (
    <>
      <Row>
        <Col
          md={1}
          className="d-flex align-items-center justify-content-center"
        >
          <span className="d-block">
            <span className="fw-bold">{result.student.uid}</span>
          </span>
        </Col>
        <Col md={5} className="d-flex align-items-center">
          <span className="d-block">
            <span className="fw-bold">{result.student.name}</span>
          </span>
        </Col>

        <Col md={5}>
          <InputGroup className=" d-flex flex-column">
            <Form.Control
              placeholder="Total mark..."
              name="total"
              type="number"
              required
              step={0.001}
              className={`${styles.input} w-100`}
              value={mark}
              onChange={(e) => {
                setMark(e.target.value);
                setIcon(false);
              }}
            />
          </InputGroup>
        </Col>

        <Col md={1} className="d-flex align-items-center">
          <span
            ref={elRef}
            className={`text-success fs-4  ${styles.check}`}
            onClick={() => editHandeler()}
          >
            {!icon ? <AiOutlineCheck /> : <BsCheckAll />}
          </span>
          <span
            className={`text-danger fs-4  ${styles.del}`}
            onClick={() => deleteHandeler()}
          >
            <BiTrash />
          </span>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default connect(null, { deleteResult, updateSemister })(OneResultRow);
