import React from "react";
import { SemisterSummary } from "../../components/SemisterSummary";
import Layout from "../../components/shared/Layout/Layout";

const SemisterSummaryPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Semister Summary`}>
        <SemisterSummary />
      </Layout>
    </div>
  );
};

export default SemisterSummaryPage;
