import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BiTrash } from "react-icons/bi";
import { connect } from "react-redux";
import { updateSaved } from "../../actions/Saved.action";
import styles from "./SemisterResultActivitySelector.module.css";

const SemisterResultActivitySelector = ({
  activities,
  setActivities,
  data,
  updateSaved,
  saved,
  activityData,
}) => {
  useEffect(() => {
    if (saved) {
      setActivities(
        saved?.savedItems.map((item) => ({
          ...item,
          name: item.label,
          grade: item.value,
        }))
      );
    } else {
      setActivities(
        activityData.map((item) => ({ ...item, grade: "A", custom: false }))
      );
    }
  }, [saved]);
  const addNewActivity = () => {
    setActivities([
      ...activities,
      { name: "", grade: "A", custom: true, id: -1 * activities.length },
    ]);
  };

  const saveActivity = async () => {
    if (activities && data && data?.semister?.length > 0 && data?.student) {
      const res = await updateSaved(
        data?.semister[0].id,
        data?.student?.id,
        activities.map((act) => ({ ...act, label: act.name, value: act.grade }))
      );
      console.log(res);

      if (res !== false) {
        setActivities(
          res.map((item) => ({
            ...item,
            name: item.label,
            grade: item.value,
          }))
        );
      }
    }
  };
  return (
    <div className="card card-body shadow my-4">
      <h4 className="pb-4">Extra Curricular Activities Control </h4>
      <Row>
        {activities ? (
          <>
            {activities.length > 0 ? (
              activities?.map((act) => (
                <Col md={6} key={act.id}>
                  <Row className="py-2">
                    <Col xs={5} className="d-flex align-items-center">
                      <Form.Control
                        value={act.name}
                        onChange={(e) =>
                          setActivities([
                            ...activities.map((item) =>
                              item.id === act.id
                                ? { ...item, name: e.target.value }
                                : item
                            ),
                          ])
                        }
                      />
                    </Col>
                    <Col xs={5}>
                      {act.dropdown ? (
                        <>
                          <Form.Select
                            value={act.grade}
                            onChange={(e) =>
                              setActivities([
                                ...activities.map((item) =>
                                  item.id === act.id
                                    ? { ...item, grade: e.target.value }
                                    : item
                                ),
                              ])
                            }
                          >
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="N/A">N/A</option>
                          </Form.Select>
                        </>
                      ) : (
                        <>
                          <Form.Control
                            value={act.grade}
                            onChange={(e) =>
                              setActivities([
                                ...activities.map((item) =>
                                  item.id === act.id
                                    ? { ...item, grade: e.target.value }
                                    : item
                                ),
                              ])
                            }
                          />
                        </>
                      )}
                    </Col>{" "}
                    <Col xs={1} className="d-flex align-items-center">
                      <Button
                        variant="danger"
                        onClick={() =>
                          setActivities([
                            ...activities.filter((item) => item.id !== act.id),
                          ])
                        }
                        size="sm"
                      >
                        <BiTrash />
                      </Button>
                    </Col>
                    <Col xs={1}>
                      <div className={styles.line}></div>
                    </Col>
                  </Row>
                </Col>
              ))
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Row>
      <div className="d-flex justify-center pt-4">
        <Button onClick={addNewActivity}>Add Activity</Button>
        <Button onClick={saveActivity} className="ms-3" variant="success">
          Save Activities
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  saved: state.saved.saved,
  activityData: state.activity.activity,
});

export default connect(mapStateToProps, { updateSaved })(
  SemisterResultActivitySelector
);
