import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";
import { AiFillPrinter } from "react-icons/ai";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getStudentMarkList } from "../../actions/Mark.action";
import { getStudentDetails } from "../../actions/Student.action";
import { months, years } from "../../constants/MonthsAndYears";
import { BASE_URL } from "../../constants/URL";
import { getMark } from "../../utils/getMark";
import LineChart from "../LineChart/LineChart";
import SectionHeading from "../SectionHeading/SectionHeading";
import styles from "./MarkListStudent.module.css";

const MarkListStudent = ({
  list,
  getStudentMarkList,
  id,
  getStudentDetails,
  student,
}) => {
  const ref = useRef(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (id !== null) {
      getStudentMarkList(id);
      getStudentDetails(id);
    }
  }, [id]);

  if (list && data === null) {
    let subjects = [];
    let months = [];

    list.map((l) => {
      if (![...subjects.map((s) => s.id)].includes(l.subject.id)) {
        subjects.push({ id: l.subject.id, name: l.subject.name });
      }
    });
    months = [...new Set(list.map((item) => item.month))].sort();

    setData({ subjects, months });
  }
  return (
    <Container className="pb-4" ref={ref}>
      {list !== null && student !== null ? (
        <div className="d-flex justify-content-end">
          <ReactToPrint
            documentTitle={`Marksheet - ${student.name}`}
            content={() => ref.current}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <span className={styles.chart_icon} onClick={handlePrint}>
                  <AiFillPrinter />
                </span>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      ) : (
        <></>
      )}
      {student && student.id === parseInt(id) ? (
        <div className="">
          {student?.image ? (
            <div className="d-flex justify-content-center align-items-center">
              <div
                className={styles.logo2}
                style={{
                  background: `url(${BASE_URL}/api/uploads/${student?.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                {/* <img
                  src={`${BASE_URL}/api/uploads/${data?.student?.image}`}
                  alt="logo"
                /> */}
              </div>
            </div>
          ) : (
            <></>
          )}
          <span className="text-center fs-4 d-block pb-3">
            Student Information
          </span>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td className="fw-bold">Name</td>
                <td>{student.name}</td>
                <td className="fw-bold">Phone</td>
                <td>{student.phone}</td>
              </tr>
              <tr>
                {student?.father ? (
                  <>
                    <td className="fw-bold">Father's Name</td>
                    <td>{student?.father}</td>
                  </>
                ) : (
                  <></>
                )}
                {student?.mother ? (
                  <>
                    <td className="fw-bold">Mother's Name</td>
                    <td>{student?.mother}</td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td className="fw-bold">Class</td>
                <td>{student.grade.name}</td>
                <td className="fw-bold">Shift</td>
                <td>{student.shift.name}</td>
              </tr>

              <tr>
                <td className="fw-bold">Batch</td>
                <td>{student.batch.name}</td>
                <td className="fw-bold">Roll</td>
                <td>{student.uid}</td>
              </tr>
              <tr>
                {student?.blood ? (
                  <>
                    <td className="fw-bold">Blood Group</td>
                    <td>{student?.blood}</td>
                  </>
                ) : (
                  <></>
                )}
                {student?.address ? (
                  <>
                    <td className="fw-bold">Address</td>
                    <td>{student?.address}</td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        <></>
      )}
      {list !== null ? (
        <Card className="crd p-md-4 pb-md-0 p-2 mt-4">
          <Card.Body>
            {list === null || !data || !student ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : list.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <>
                {years
                  .filter(
                    (y) =>
                      list.filter((yearRes) => yearRes.year === y).length > 0
                  )
                  .map((year, l) => (
                    <Fragment key={l}>
                      <SectionHeading
                        title={`Individual Result`}
                        subtitle={`Year : ${year}`}
                        grade={student?.gradeId}
                        shift={student?.shiftId}
                        batch={student?.batchId}
                      />
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Subject</th>
                            {data.months.map((mnt) => (
                              <th key={mnt}>{months[mnt]}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.subjects.map((sub, i) => (
                            <tr key={i}>
                              <td>{sub.name}</td>
                              {data?.months.map((mnt, j) => (
                                <td key={j}>
                                  {getMark(
                                    list.filter(
                                      (l) =>
                                        l.subject.id === sub.id &&
                                        l.month === mnt
                                    ).length > 0
                                      ? list.filter(
                                          (l) =>
                                            l.subject.id === sub.id &&
                                            l.month === mnt
                                        )[0].percent
                                      : 0
                                  )}
                                  %
                                </td>
                              ))}
                            </tr>
                          ))}

                          {/* {list
                            .filter((yearRes) => yearRes.year === year)
                            .sort((a, b) => a.month - b.month)
                            .map((markItem, i) => (
                              <tr
                                key={markItem.id}
                                className={`${
                                  i == 0 ||
                                  markItem.month >
                                    list
                                      .filter((r) => r.year === year)
                                      .sort((a, b) => a.month - b.month)[i - 1]
                                      .month
                                    ? styles.b_top
                                    : ""
                                }`}
                              >
                                <td>{months[markItem.month]}</td>
                                <td>{markItem.year}</td>
                                <td>{markItem.subject.name}</td>
                                <td>{getMark(markItem.percent)}%</td>
                                <td>{getGrade(markItem.percent)}</td>
                              </tr>
                            ))} */}
                        </tbody>
                      </Table>
                      <div className={styles.chart}>
                        <LineChart
                          data={list
                            .filter((l) => l.year === year)
                            .sort((a, b) => a.month - b.month)
                            .reverse()}
                        />
                      </div>
                    </Fragment>
                  ))}
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.mark.student_mark,
  student: state.student.selected_student,
});

export default connect(mapStateToProps, {
  getStudentMarkList,
  getStudentDetails,
})(MarkListStudent);
