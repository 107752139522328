import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { BiTrash } from "react-icons/bi";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getBatchStudentsSemister } from "../../actions/Batch.action";
import { createSemister } from "../../actions/Semister.action";
import { getSubjectDetails } from "../../actions/Subject.action";
import { years } from "../../constants/MonthsAndYears";
import styles from "./AddSemisterForm.module.css";

const AddSemisterForm = ({
  grades,
  getBatchStudentsSemister,
  createSemister,
  sub,
  getSubjectDetails,
}) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [subject, setSubject] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("1st Term");
  const [loading, setLoading] = useState(false);

  const [students, setStudents] = useState(null);

  const navigate = useNavigate();

  const selectHandeler = async () => {
    const list = await getBatchStudentsSemister(
      batch,
      year,
      month,
      grade,
      shift,
      subject
    );

    getSubjectDetails(subject);

    setStudents(
      list.map((l) => ({
        id: l.id,
        studentId: l.id,
        uid: l.uid,
        name: l.name,
        phone: l.phone,
        total: 0,
      }))
    );
  };

  const submitHandeler = async () => {
    setLoading(true);

    let inputValidation = true;

    students.map((std) => {
      if (std.total > sub?.mark || std.total < 0) {
        inputValidation = false;
        console.log(std);
      }
    });

    if (!inputValidation) {
      toast.error("You have invalid mark!");
      setLoading(false);
      return;
    }

    let check = await createSemister(
      month,
      year,
      batch,
      students,
      grade,
      shift,
      subject
    );

    if (check === true) {
      setLoading(false);
      navigate("/dashboard");
    } else {
      setLoading(false);
    }
  };

  const deleteHandeler = (id) => {
    setStudents([...students.filter((st) => st.id !== id)]);
  };
  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            {grade !== "" && shift !== "" && batch !== "" && subject !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            {grade !== "" ? (
              <Col md={4} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="subject" className="d-block">
                    Subject
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                >
                  <option value={""}>Select Subject</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .subjects.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="month" className="d-block">
                  Term
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                id="month"
                name="month"
              >
                <option value={`1st Term`}>1st Term</option>
                <option value={`2nd Term`}>2nd Term</option>
                <option value={`3rd Term`}>3rd Term</option>
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {students ? (
        <Card bg="white" text="dark" className={`crd shadow mt-4`}>
          <Card.Body>
            <h1 className="fs-4 fw-normal py-3">
              Input marks for{" "}
              {sub ? (
                <>
                  <b>{sub.name}</b> | Max mark : <b>{sub.mark}</b>{" "}
                </>
              ) : (
                "Loading..."
              )}
            </h1>
            <Row>
              <Col
                md={1}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="d-block fw-bold">Roll</span>
              </Col>
              <Col md={5} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Name</span>
              </Col>
              <Col md={5} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Total Mark</span>
              </Col>

              <Col md={1} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Delete</span>
              </Col>
            </Row>
            <hr />
            {students.map((student) => (
              <Fragment key={student.id}>
                <Row>
                  <Col
                    md={1}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="d-block">
                      <span className="fw-bold">{student.uid}</span>
                    </span>
                  </Col>
                  <Col md={5} className="d-flex align-items-center">
                    <span className="d-block">
                      <span className="fw-bold">{student.name}</span>
                    </span>
                  </Col>

                  <Col md={5}>
                    <InputGroup className=" d-flex flex-column">
                      <Form.Control
                        placeholder="Total mark..."
                        name="total"
                        type="number"
                        step={0.001}
                        required
                        className={`${styles.input} w-100`}
                        onChange={(e) =>
                          setStudents([
                            ...students.map((std) =>
                              student.id === std.id
                                ? { ...std, total: parseFloat(e.target.value) }
                                : std
                            ),
                          ])
                        }
                      />
                    </InputGroup>
                  </Col>

                  <Col md={1} className="d-flex align-items-center">
                    <span
                      className={`text-danger fs-4  ${styles.del}`}
                      onClick={() => deleteHandeler(student.id)}
                    >
                      <BiTrash />
                    </span>
                  </Col>
                </Row>
                <hr />
              </Fragment>
            ))}
            <Row>
              <Col md={6}></Col>
            </Row>
            <Button
              onClick={submitHandeler}
              variant="primary"
              className="btn_primary"
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  sub: state.subject.selected_subject,
});

export default connect(mapStateToProps, {
  getBatchStudentsSemister,
  createSemister,
  getSubjectDetails,
})(AddSemisterForm);
