import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { remarkArray } from "../../constants/remarkArray";

const RemarkSelector = ({ remark, setRemark }) => {
  return (
    <div className="card card-body shadow my-4 py-4 d-flex">
      <Row>
        <Col md={6}>
          <h4 className="">Remark Control </h4>
        </Col>
        <Col md={6}>
          <Form.Select
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          >
            {remarkArray.map((rem, i) => (
              <option value={rem.name} key={i}>
                {rem.text}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
    </div>
  );
};

export default RemarkSelector;
