import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import ShiftList from "../../components/ShiftList/ShiftList";

const ShiftListPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Shift">
        <ShiftList />
      </Layout>
    </div>
  );
};

export default ShiftListPage;
