import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import { AiFillPrinter } from "react-icons/ai";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { deleteGrade, getGradeList } from "../../actions/Grade.action";
import { getTopSemisterResult } from "../../actions/Semister.action";
import { years } from "../../constants/MonthsAndYears";
import { getGrade } from "../../utils/getGrade";
import { getMark } from "../../utils/getMark";
import MarkDistribution from "../MarkDistribution/MarkDistribution";
import SectionHeading from "../SectionHeading/SectionHeading";
import styles from "./FullTopSmister.module.css";

const FullTopSmister = ({ data }) => {
  const [rawList, setRawList] = useState(data === null ? [] : data.data);
  const [extra, setExtra] = useState(data === null ? [] : data.extra);

  const ref = useRef(null);

  useEffect(() => {
    if (data !== null) {
      setRawList(data.data);
      setExtra(data.extra);
    }
  }, [data]);

  return (
    <Container className="pb-4">
      {rawList !== null ? (
        <Card className="crd p-md-2 pb-md-0 p-2 mt-4">
          <Card.Body>
            {rawList === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : rawList.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <>
                <div className="d-flex justify-content-end ">
                  <div className="">
                    <ReactToPrint
                      documentTitle={`Marksheet - ${extra.term} - ${extra.year}`}
                      content={() => ref.current}
                    >
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <span
                            className={styles.chart_icon}
                            onClick={handlePrint}
                          >
                            <AiFillPrinter />
                          </span>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                  </div>
                </div>
                <div className="p-3" ref={ref}>
                  <SectionHeading
                    title={`Top Semister Final Performance`}
                    year={extra?.year}
                    grade={extra?.gradeId}
                    shift={extra?.shiftId}
                    batch={extra?.batchId}
                  />
                  <MarkDistribution />

                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Roll</th>
                        <th>Name</th>
                        {extra.batchId !== "" ? <></> : <th>Batch</th>}
                        {extra.shiftId !== "" ? <></> : <th>Shift</th>}

                        {/* <th>Total Mark</th> */}
                        <th>Percent</th>
                        <th>Grade</th>
                        <th>Position</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rawList !== null &&
                        rawList
                          .sort(function (a, b) {
                            if (
                              parseFloat(getMark(a.percent)) ===
                              parseFloat(getMark(b.percent))
                            ) {
                              return parseInt(a.id) > parseInt(b.id) ? 1 : -1;
                            }
                            if (
                              parseFloat(getMark(a.percent)) <
                              parseFloat(getMark(b.percent))
                            )
                              return 1;
                            if (
                              parseFloat(getMark(a.percent)) >
                              parseFloat(getMark(b.percent))
                            )
                              return -1;
                            return 0;
                          })
                          .map((markItem, i) => (
                            <tr key={markItem.id}>
                              <td>{markItem.uid}</td>
                              <td>
                                <span className="link-custom">
                                  {markItem.name}{" "}
                                </span>
                              </td>
                              {extra.batchId !== "" ? (
                                <></>
                              ) : (
                                <td>{markItem.batch.name}</td>
                              )}
                              {extra.shiftId !== "" ? (
                                <></>
                              ) : (
                                <td>{markItem.shift.name}</td>
                              )}

                              {/* <td>{markItem.mark}</td> */}
                              <td>{getMark(markItem.percent)}%</td>
                              <td>{getGrade(getMark(markItem.percent))}</td>
                              <td>{i + 1}</td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                  <div className="d-flex align-items center justify-content-center">
                    <div className="d-flex  flex-md-row flex-column pt-md-0 pt-3">
                      <span className="d-block pe-3">
                        <span className="fw-bold">A++</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "A++")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A+</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "A+")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "A")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A-</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "A-")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">B</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "B")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">C</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "C")
                            .length
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default FullTopSmister;
