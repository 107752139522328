import React from "react";
import { connect } from "react-redux";
import { FullSemisterTopForm } from "../../components/FullSemisterTopForm";
import FullTopSmister from "../../components/FullTopSmister/FullTopSmister";
import Layout from "../../components/shared/Layout/Layout";

const FullSemisterTopResultPage = ({ data }) => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Full Semister Top Result">
        <FullSemisterTopForm />
        {data ? <FullTopSmister data={data} /> : <></>}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.mark.full_semister_top,
});

export default connect(mapStateToProps, null)(FullSemisterTopResultPage);
