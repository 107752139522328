import React from "react";

import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";
import ViewResults from "../../components/ViewResults/ViewResults";

const PublicMarkListPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={`Monthly Subject-wise Class Performance`}>
        <ViewResults />
      </PublicLayout>
    </div>
  );
};

export default PublicMarkListPage;
