import React, { useEffect, useState } from "react";
import { Card, Container, Col, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./IndividualResultForm.module.css";
import { connect } from "react-redux";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { deleteStudent, getStudentList } from "../../actions/Student.action";
import { getGradeList } from "../../actions/Grade.action";
import { toast } from "react-toastify";

const IndividualResultForm = ({
  getStudentList,
  grades,
  getGradeList,
  hide,
  searchParams,
  publicView,
}) => {
  const [roll, setRoll] = useState("");
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());

  const navigate = useNavigate();

  useEffect(() => {
    if (grades === null) {
      getGradeList();
    }
  }, []);

  const selectHandeler = async () => {
    if (!year || !grade || !shift || !batch || !roll) {
      toast.error("Please select all field!");
    } else {
      let check = await getStudentList(year, grade, shift, batch, roll);

      if (check.length === 1 && check !== false) {
        navigate(
          hide === true
            ? publicView
              ? `/individual/${check[0].id}`
              : `/student/${check[0].id}`
            : searchParams && searchParams === "individual"
            ? `/admin/individual/${check[0].id}`
            : `/admin/mark/student/${check[0].id}`
        );
      } else if (check === false) {
        toast.error("Something went wrong!");
        return;
      } else {
        toast.error("Please fill out all field properly!");
        return false;
      }
    }
  };

  return (
    <Container className="pb-4">
      {hide === true ? (
        <></>
      ) : (
        <div className="d-flex justify-content-end align-items-center pb-3 ">
          <div className="text-right">
            <Link to="/admin/student/add" className={styles.add}>
              <BsFileEarmarkPlus />
            </Link>
          </div>
        </div>
      )}

      <Card bg="white" text="dark" className={`crd shadow mb-4`}>
        <Card.Body>
          <Row>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
                value={year}
              >
                <option value={""}>Select Year</option>
                {Array.from({ length: 20 }, (_, i) => i + 2010)
                  .reverse()
                  .map((item, i) => (
                    <option key={i} value={`${item}`}>
                      {item}
                    </option>
                  ))}
              </Form.Select>
            </Col>

            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="roll" className="d-block">
                  Roll
                </label>
              </div>
              <Form.Control
                onChange={(e) => {
                  setRoll(e.target.value);
                }}
                value={roll}
                type="text"
                id="roll"
                name="roll"
              ></Form.Control>
            </Col>
            <Col
              md={4}
              className="d-md-flex jsutify-content-end align-items-end py-3 d-none"
            >
              <Button
                onClick={selectHandeler}
                variant="primary"
                type="submit"
                className="btn_primary"
              >
                Select
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            <Col
              md={4}
              className="d-md-none jsutify-content-end align-items-end py-3 d-flex"
            >
              <Button
                onClick={selectHandeler}
                variant="primary"
                type="submit"
                className="btn_primary"
              >
                Select
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.student.student,
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  getStudentList,
  deleteStudent,
  getGradeList,
})(IndividualResultForm);
