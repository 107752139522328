import { Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import React, { useEffect, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";
import { BsFileEarmarkPlus, BsTrash } from "react-icons/bs";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteActivity, getActivityList } from "../../actions/Activity.action";
import styles from "./ExtraList.module.css";

const ExtraList = ({ list, getActivityList, deleteActivity }) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);

  const modals = useModals();

  useEffect(() => {
    if (list === null) {
      getActivityList();
    }
    if (list !== null) {
      setRawList(list);
    }
  }, [list]);

  const deleteHandeler = (id) =>
    modals.openConfirmModal({
      title: "Delete this activity",
      centered: true,
      children: (
        <Text size="md">
          Are you sure you want to delete this activity? This action is
          destructive and you can not undo it.
        </Text>
      ),
      labels: { confirm: "Delete Activity", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => confirmHandeler(id),
    });

  const confirmHandeler = async (id) => {
    let check = await deleteActivity(id);
    if (check === true) {
      toast.success("Activity deleted successfully");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <Container className="pb-4">
      <div className="d-flex justify-content-end align-items-center pb-3 ">
        <div className="text-right">
          <Link to="/admin/extra/add" className={styles.add}>
            <BsFileEarmarkPlus />
          </Link>
        </div>
      </div>
      <Card className="crd p-md-4 pb-md-0 p-2">
        <Card.Body>
          {rawList === null ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: 600 }}
            >
              <Spinner variant="dark" animation="grow" />
            </div>
          ) : rawList.length === 0 ? (
            <span className="text-center fs-4 d-block pb-3">
              No Activity Found
            </span>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Activity</th>
                    <th>
                      <div className="d-flex justify-content-center align-items-center">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rawList !== null &&
                    rawList.map((activity, i) => (
                      <tr key={activity.id}>
                        <td>{i + 1}</td>
                        <td>{activity.name}</td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            <span
                              onClick={() => deleteHandeler(activity.id)}
                              className={`fw-bold ${styles.link}`}
                            >
                              <BsTrash />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.activity.activity,
});

export default connect(mapStateToProps, { getActivityList, deleteActivity })(
  ExtraList
);
