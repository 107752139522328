import { GET_SAVED } from "../constants/Type";

const initialState = {
  saved: null,
  loading: true,
};

const savedReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SAVED:
      return {
        ...state,
        saved: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default savedReducer;
