import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import { AiFillPrinter } from "react-icons/ai";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { deleteGrade, getGradeList } from "../../actions/Grade.action";
import { getTopSemisterResult } from "../../actions/Semister.action";
import { years } from "../../constants/MonthsAndYears";
import { getGrade } from "../../utils/getGrade";
import { getMark } from "../../utils/getMark";
import MarkDistribution from "../MarkDistribution/MarkDistribution";
import SectionHeading from "../SectionHeading/SectionHeading";
import styles from "./TopSemisterResult.module.css";

const TopSemisterResult = ({
  list,
  getGradeList,
  extra,
  grades,
  getTopSemisterResult,
}) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [term, setTerm] = useState("1st Term");
  const [subjectList, setSubjectList] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    if (!grades) {
      getGradeList();
    }

    if (list !== null) {
      setRawList(list);
      //   let tempSubs = [];
      //   list.map((item) => {
      //     if (!tempSubs.includes(item.subject.name)) {
      //       tempSubs.push(item.subject.name);
      //     }
      //     return item;
      //   });

      //   setSubjectList([...tempSubs]);
    }
  }, [list]);

  const selectHandeler = async () => {
    await getTopSemisterResult(batch, year, term, grade, shift);
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grades && grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" ? (
              <Col
                md={3}
                className="d-none d-md-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="term" className="d-block">
                  Term
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setTerm(e.target.value);
                }}
                value={term}
                id="term"
                name="term"
              >
                <option value={`1st Term`}>1st Term</option>
                <option value={`2nd Term`}>2nd Term</option>
                <option value={`3rd Term`}>3rd Term</option>
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                value={year}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col
                md={3}
                className="d-flex d-md-none jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Card.Body>
      </Card>
      {rawList !== null ? (
        <Card className="crd p-md-2 pb-md-0 p-2 mt-4">
          <Card.Body>
            {rawList === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : rawList.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <>
                <div className="d-flex justify-content-end ">
                  <div className="">
                    <ReactToPrint
                      documentTitle={`Marksheet - ${extra.term} - ${extra.year}`}
                      content={() => ref.current}
                    >
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <span
                            className={styles.chart_icon}
                            onClick={handlePrint}
                          >
                            <AiFillPrinter />
                          </span>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                  </div>
                </div>
                <div className="p-3" ref={ref}>
                  <span className="text-center fs-4 d-block ">
                    Top Semister Result
                  </span>
                  <SectionHeading
                    title={`Top Semister Performance`}
                    year={extra?.year}
                    grade={extra?.gradeId}
                    shift={extra?.shiftId}
                    batch={extra?.batchId}
                  />
                  <MarkDistribution />

                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Roll</th>
                        <th>Name</th>
                        {extra.batchId !== "" ? <></> : <th>Batch</th>}
                        {extra.shiftId !== "" ? <></> : <th>Shift</th>}

                        {/* <th>Total Mark</th> */}
                        <th>Percent</th>
                        <th>Grade</th>
                        <th>Position</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rawList !== null &&
                        rawList
                          .sort(function (a, b) {
                            if (
                              parseFloat(getMark(a.percentFinal)) ===
                              parseFloat(getMark(b.percentFinal))
                            ) {
                              return parseInt(a.id) > parseInt(b.id) ? 1 : -1;
                            }
                            if (
                              parseFloat(getMark(a.percentFinal)) <
                              parseFloat(getMark(b.percentFinal))
                            )
                              return 1;
                            if (
                              parseFloat(getMark(a.percentFinal)) >
                              parseFloat(getMark(b.percentFinal))
                            )
                              return -1;
                            return 0;
                          })
                          .map((markItem, i) => (
                            <tr key={markItem.id}>
                              <td>{markItem.uid}</td>
                              <td>
                                <span className="link-custom">
                                  {markItem.name}{" "}
                                </span>
                              </td>
                              {extra.batchId !== "" ? (
                                <></>
                              ) : (
                                <td>{markItem.batch.name}</td>
                              )}
                              {extra.shiftId !== "" ? (
                                <></>
                              ) : (
                                <td>{markItem.shift.name}</td>
                              )}

                              {/* <td>{markItem.mark}</td> */}
                              <td>{getMark(markItem.percentFinal)}%</td>
                              <td>
                                {getGrade(
                                  getMark(
                                    (markItem.total * 100) / markItem.full
                                  )
                                )}
                              </td>
                              <td>{i + 1}</td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                  <div className="d-flex align-items center justify-content-center">
                    <div className="d-flex  flex-md-row flex-column pt-md-0 pt-3">
                      <span className="d-block pe-3">
                        <span className="fw-bold">A++</span> ={" "}
                        {
                          rawList.filter(
                            (l) => getGrade(l.percentFinal) === "A++"
                          ).length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A+</span> ={" "}
                        {
                          rawList.filter(
                            (l) => getGrade(l.percentFinal) === "A+"
                          ).length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A</span> ={" "}
                        {
                          rawList.filter(
                            (l) => getGrade(l.percentFinal) === "A"
                          ).length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A-</span> ={" "}
                        {
                          rawList.filter(
                            (l) => getGrade(l.percentFinal) === "A-"
                          ).length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">B</span> ={" "}
                        {
                          rawList.filter(
                            (l) => getGrade(l.percentFinal) === "B"
                          ).length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">C</span> ={" "}
                        {
                          rawList.filter(
                            (l) => getGrade(l.percentFinal) === "C"
                          ).length
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.mark.top_semister,
  extra: state.mark.semister_extra,
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  getGradeList,
  deleteGrade,
  getTopSemisterResult,
})(TopSemisterResult);
