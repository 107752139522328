import React from "react";
import { RatioGraph } from "../../components/RatioGraph";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicCompariosnGraphPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={`Comparison Graph`}>
        <RatioGraph />
      </PublicLayout>
    </div>
  );
};

export default PublicCompariosnGraphPage;
