import React from "react";
import TopSemsterResult from "../../components/TopSemsterResult/TopSemsterResult";

import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicTopSemisterResult = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={`Top Semister Result`}>
        <TopSemsterResult />
      </PublicLayout>
    </div>
  );
};

export default PublicTopSemisterResult;
