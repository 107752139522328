import React from "react";
import { AcademicGrapg } from "../../components/AcademicGrapg";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicAcademicGraphPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={`Academic Graph`}>
        <AcademicGrapg />
      </PublicLayout>
    </div>
  );
};

export default PublicAcademicGraphPage;
