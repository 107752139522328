import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import { StudentList } from "../../components/StudentList";

const StudentListPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Students">
        <StudentList />
      </Layout>
    </div>
  );
};

export default StudentListPage;
