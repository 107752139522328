import React from "react";
import { AlarmingStudents } from "../../components/AlarmingStudents";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicAlarmingStudentPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={`Alarming Students`}>
        <AlarmingStudents />
      </PublicLayout>
    </div>
  );
};

export default PublicAlarmingStudentPage;
