import React from "react";
import BatchOverviewResult from "../../components/BatchOverviewResult/BatchOverviewResult";

import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicTopResult = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={` Monthly Top Result`}>
        <BatchOverviewResult />
      </PublicLayout>
    </div>
  );
};

export default PublicTopResult;
