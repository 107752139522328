import React from "react";
import { useParams } from "react-router-dom";
import { MarkListStudentMonthWise } from "../../components/MarkListStudentMonthWise";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicMarkListStudentMonthlyPage = () => {
  const { id } = useParams();
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title="Monthly Basis Serial Result">
        <MarkListStudentMonthWise id={id} />
      </PublicLayout>
    </div>
  );
};

export default PublicMarkListStudentMonthlyPage;
