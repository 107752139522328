import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getSubjectDetails } from "../../actions/Subject.action";
import AddSubjectForm from "../../components/AddSubjectForm/AddSubjectForm";
import Layout from "../../components/shared/Layout/Layout";

const AddSubjectPage = ({ edit, subject, getSubjectDetails }) => {
  const { id } = useParams();
  useEffect(() => {
    if (edit && id && !subject) {
      getSubjectDetails(id);
    }
  }, [id]);
  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} Subject`}>
        {edit && id && subject ? (
          <AddSubjectForm edit={true} data={subject} />
        ) : (
          <AddSubjectForm />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subject: state.subject.selected_subject,
});

export default connect(mapStateToProps, { getSubjectDetails })(AddSubjectPage);
