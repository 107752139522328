import React from "react";
import BatchOverviewResult from "../../components/BatchOverviewResult/BatchOverviewResult";
import Layout from "../../components/shared/Layout/Layout";

const BatchOverviewPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Monthly Top Result`}>
        <BatchOverviewResult />
      </Layout>
    </div>
  );
};

export default BatchOverviewPage;
