import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getGradeList } from "../../actions/Grade.action";
import { AddGradeForm } from "../../components/AddGradeForm";
import Layout from "../../components/shared/Layout/Layout";

const AddGradePage = ({ edit, grades, getGradeList }) => {
  const { id } = useParams();
  useEffect(() => {
    if (edit && id && !grades) {
      getGradeList();
    }
  }, [id]);
  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} Class`}>
        {edit && id && grades ? (
          <AddGradeForm
            edit={true}
            data={grades.filter((item) => item.id === parseInt(id))[0]}
          />
        ) : (
          <AddGradeForm />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(AddGradePage);
