import React from "react";
import { IndividualResultForm } from "../../components/IndividualResultForm";
import Layout from "../../components/shared/Layout/Layout";

const IndividualResult = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Individual Result">
        <IndividualResultForm />
      </Layout>
    </div>
  );
};

export default IndividualResult;
