import React, { useEffect, useRef, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { AiFillPrinter } from "react-icons/ai";
import Moment from "react-moment";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getGradeList } from "../../actions/Grade.action";
import { getTopSemisterResult } from "../../actions/Semister.action";
import { remarkArray } from "../../constants/remarkArray";
import { BASE_URL } from "../../constants/URL";
import { getGrade } from "../../utils/getGrade";
import { getMark } from "../../utils/getMark";
import SectionHeading from "./SectionHeading/SectionHeading";
import ActivitiesList from "./ActivitiesList";
import styles from "./SemisterResultPrint.module.css";
import logo from "../../Assets/logo.jpeg";

const SemisterResultPrint = ({
  data,
  grade,
  getGradeList,
  activities,
  remark,
  list,
  getTopSemisterResult,
  extra,
}) => {
  const ref = useRef(null);
  const [toatalStdMark, setTotalStdMark] = useState({ total: 0, gained: 0 });
  const [results, setResults] = useState([]);
  useEffect(() => {
    const dataFetch = async () => {
      if (data?.semister[0]) {
        let check = await getTopSemisterResult(
          data?.semister[0].batchId,
          data?.semister[0].year,
          data?.semister[0].term,
          data?.semister[0].gradeId,
          data?.semister[0].shiftId
        );
      }
    };
    dataFetch();
  }, [data?.semister]);

  const workingDay = (id) => {
    let total = 0;
    data.marks
      .filter((m) => m.subjectId === id)
      .map((item) => {
        total += item.working_day;
      });

    return total;
  };

  const attendence = (id) => {
    let total = 0;
    data.marks
      .filter((m) => m.subjectId === id)
      .map((item) => {
        total += item.present;
      });

    return total;
  };
  const classPerformancePercent = (subject) => {
    let total = 0;
    let count = 0;

    data.marks
      .filter((m) => m.subjectId === subject.id)
      .map((item) => {
        total += item.percent;
        count++;
      });

    return getMark((total * 0.4 * subMarkNormalizer(subject)) / count);
  };
  const semisterMark = (subject) => {
    let total = 0;

    if (
      data.semister[0].results.filter((m) => m.subjectId === subject.id)
        .length > 0
    ) {
      let list = data.semister[0].results
        .sort((a, b) => parseInt(b.id) - parseInt(a.id))
        .filter((m) => m.subjectId === subject.id);
      total += parseFloat(getMark(list[0].mark));
    }

    return getMark(total);
  };

  const attendencePercent = (id) => {
    if (attendence(id) === 0 || workingDay(id) === 0) {
      return 0;
    } else {
      return (attendence(id) * 100) / workingDay(id);
    }
  };

  const subMarkNormalizer = (subject, mark) => {
    return subject.mark / 100;
  };

  const getTotalMark = (subject) => {
    const res =
      attendencePercent(subject.id) * subMarkNormalizer(subject) * 0.1 +
      ((semisterMark(subject) * subMarkNormalizer(subject) * 100) /
        subject.mark) *
        0.5 +
      classPerformancePercent(subject) * 1.0;

    return getMark(res);
  };

  const getFullTotal = (results) => {
    let total = 0;
    results.map((r) => {
      total += parseFloat(getMark(r.mark));
    });

    return getMark(total);
  };
  const getFullGrade = () => {
    let total = 0;
    results.map((r) => {
      total += parseFloat(getMark(r.grade));
    });
    return getGrade(getMark(total / results.length));
  };
  const getFullPercent = () => {
    let total = 0;
    results.map((r) => {
      total += parseFloat(r.grade);
    });
    return getMark(parseFloat(total / results.length));
  };
  const getTotalSubjectMarks = (results) => {
    let total = 0;
    results.map((r) => {
      total += parseInt(r.total);
    });
    return getMark(total);
  };
  useEffect(() => {
    if (!grade) {
      getGradeList();
    }
    if (data && grade && data?.semister) {
      let arr = [];
      let totalSub = 0;
      let totalMark = 0;
      grade
        .filter((g) => g.id === parseInt(data.gradeId))[0]
        .subjects.sort(function (x, y) {
          return parseInt(x.id) - parseInt(y.id);
        })
        .map((subject) => {
          const res = parseFloat(
            getMark(
              attendencePercent(subject.id) * subMarkNormalizer(subject) * 0.1 +
                ((semisterMark(subject) * subMarkNormalizer(subject) * 100) /
                  subject.mark) *
                  0.5 +
                classPerformancePercent(subject) * 1.0
            )
          );

          arr.push({
            id: subject.id,
            mark: res,
            grade: parseFloat((100 / subject.mark) * res),
            total: subject.mark,
          });
          totalMark += parseFloat(res);
          totalSub += parseFloat(subject.mark);
        });
      setResults(arr);
      //console.log(arr);
      setTotalStdMark((_) => ({
        total: totalSub,
        gained: getMark(totalMark),
      }));
    }
  }, [data?.student?.id]);

  return (
    <div className={`card card-body shadow bg-white ${styles.wrapper}`}>
      <div className="d-flex justify-content-end ">
        <div className="">
          <ReactToPrint
            documentTitle={`${data.term} Marksheet`}
            content={() => ref.current}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <span className={styles.chart_icon} onClick={handlePrint}>
                  <AiFillPrinter />
                </span>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      </div>
      {data && data.semister && data.semister.length > 0 ? (
        <div ref={ref} className={styles.wrap}>
          {data?.student ? (
            <div className="position-relative">
              {data?.student?.image ? (
                <>
                  <img src={logo} alt="logo" className={styles.logo} />
                  <div className={styles.logo2}>
                    <img
                      src={`${BASE_URL}/api/uploads/${data?.student?.image}`}
                      className="w-100"
                      alt=""
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <SectionHeading
                noLogo={data?.student?.image}
                title={
                  <>
                    <span className="d-block text-center pb-2">
                      Academic Transcript
                    </span>

                    <span className="d-block text-center">
                      {data?.term} Assessment - {data?.semister[0]?.year}
                    </span>
                  </>
                }
                subtitle={`Student Information`}
                grade={data?.student?.gradeId}
                shift={data?.student?.shiftId}
                batch={data?.student?.batchId}
              />

              <Container fluid className="py-3">
                <div className={styles.std_info}>
                  <div className={styles.bordr_right}>
                    <div>
                      <div className="d-flex justify-content-center align-items-center ">
                        <span className={styles.info_heading}>Name :</span>

                        <span className={styles.info_text}>
                          {data?.student.name}
                        </span>
                      </div>
                    </div>
                    {data?.student.address ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Address :</span>

                          <span className={styles.info_text}>
                            {data?.student.address}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {data?.student.blood ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>
                            Blood Group :
                          </span>

                          <span className={styles.info_text}>
                            {data?.student.blood}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={styles.bordr_right}>
                    {data?.student.father ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Father:</span>

                          <span className={styles.info_text}>
                            {data?.student.father}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {data?.student.dob ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>
                            Date of Birth :
                          </span>

                          <span className={styles.info_text}>
                            <Moment format="DD MMMM YYYY">
                              {data?.student.dob}
                            </Moment>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div>
                      <div className="d-flex justify-content-center fw-bold align-items-center ">
                        <span className={styles.info_heading}>Roll :</span>

                        <span className={styles.info_text}>
                          {data?.student.uid}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.bordr_right}>
                    {data?.student.mother ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Mother :</span>

                          <span className={styles.info_text}>
                            {data?.student.mother}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {data?.student.phone ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Phone :</span>

                          <span className={styles.info_text}>
                            {data?.student.phone}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Container>
            </div>
          ) : (
            <></>
          )}
          <span className="text-center h6 d-block ">
            Subejct Basis Letter, Grade & Marks
          </span>
          <div className="d-flex align-items center justify-content-center pb-3">
            <div className="d-flex justify-content-center">
              <span className="d-block pe-3">
                <span className="fw-bold">A++</span> = (91-100)
              </span>
              <span className="d-block pe-3">
                <span className="fw-bold">A+</span> = (81-90)
              </span>
              <span className="d-block pe-3">
                <span className="fw-bold">A</span> = (71-80)
              </span>
              <span className="d-block pe-3">
                <span className="fw-bold">A-</span> = (61-70)
              </span>
              <span className="d-block pe-3">
                <span className="fw-bold">B</span> = (51-60)
              </span>
              <span className="d-block pe-3">
                <span className="fw-bold">C</span> = {"(<51)"}{" "}
              </span>
            </div>
          </div>
          <Table striped bordered hover responsive className="w-100" size="sm">
            <thead>
              <tr>
                <th className="text-center">Subject</th>
                <th className="text-center">Full Marks</th>
                <th className="text-center">Gained Marks</th>
                <th className="text-center">Grade</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                grade &&
                grade
                  .filter((g) => g.id === parseInt(data.gradeId))[0]
                  .subjects.sort(function (x, y) {
                    return parseInt(x.id) - parseInt(y.id);
                  })
                  .map((subject) => (
                    <tr key={subject.id}>
                      <td>{subject.name}</td>
                      <td className="text-center">{subject.mark}</td>

                      <td className="text-center">{getTotalMark(subject)}</td>
                      <td className="text-center">
                        {getGrade(
                          getMark(
                            (100 / subject.mark) *
                              (attendencePercent(subject.id) *
                                subMarkNormalizer(subject) *
                                0.1 +
                                ((semisterMark(subject) *
                                  subMarkNormalizer(subject) *
                                  100) /
                                  subject.mark) *
                                  0.5 +
                                classPerformancePercent(subject) * 1.0)
                          )
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
          <div
            className={`d-flex justify-content-center align-items-center pb-4 ${styles.overall_info}`}
          >
            <span className="d-block">
              <b>Total Mark = {getMark(toatalStdMark.total)}</b>
            </span>
            <span className="d-block ps-4">
              <b>Total Gained Mark = {getMark(toatalStdMark.gained)}</b>
            </span>
            <span className="d-block ps-4">
              <b>
                {" "}
                Gained Percent ={" "}
                {getMark((toatalStdMark.gained * 100) / toatalStdMark.total)}%
              </b>
            </span>
            <span className="d-block ps-4">
              <b>
                Overall Grade ={" "}
                {getGrade(
                  getMark((toatalStdMark.gained * 100) / toatalStdMark.total)
                )}
              </b>
            </span>
            {list ? (
              <span className="d-block ps-4">
                <b>
                  Position ={" "}
                  {list
                    .sort(function (a, b) {
                      if (
                        parseFloat(getMark(a.percentFinal)) ===
                        parseFloat(getMark(b.percentFinal))
                      ) {
                        return parseInt(a.id) > parseInt(b.id) ? 1 : -1;
                      }
                      if (
                        parseFloat(getMark(a.percentFinal)) <
                        parseFloat(getMark(b.percentFinal))
                      )
                        return 1;
                      if (
                        parseFloat(getMark(a.percentFinal)) >
                        parseFloat(getMark(b.percentFinal))
                      )
                        return -1;
                      return 0;
                    })
                    .findIndex((el) => el.id === data?.student?.id) + 1}
                </b>
              </span>
            ) : (
              ""
            )}
            <span className="d-block ps-4">
              <b>
                Overall ={" "}
                {getFullPercent() >= 50.0 ? (
                  <span className="text-success fw-bold">Passed</span>
                ) : (
                  <span className="text-danger fw-bold">Failed</span>
                )}
              </b>
            </span>
          </div>

          <div className={`${styles.std_overview} pb-3`}>
            <div className="">
              <div className={styles.std_overview_inner}>
                <div className="pb-2">
                  <span className="d-block text-center fw-bold ">
                    Extra Curricular Activities
                  </span>
                  <div className="ps-3 text-center pb-2">
                    <small className="me-3">
                      <b>( A = </b> Excellent
                    </small>
                    <small className="me-3">
                      <b>B = </b> Good
                    </small>
                    <small className="me-3">
                      <b>C = </b> Average
                    </small>
                    <small className="me-3">
                      <b>D = </b> Unsatisfactory
                    </small>
                    <small className="me-3">
                      <b>N/A = </b> Not Applicable )
                    </small>
                  </div>
                  <ActivitiesList data={activities} />
                </div>
                <div className="">
                  <div className="">
                    <span className="d-block text-center fw-bold text-dark h6">
                      Overall Assessment :
                      <span className="ms-2 text-success">
                        {remarkArray.filter((r) => r.name === remark)[0].text}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h3 className="text-center py-4">No semister result found</h3>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  grade: state.grade.grade,
  list: state.mark.top_semister,
  extra: state.mark.semister_extra,
});

export default connect(mapStateToProps, { getGradeList, getTopSemisterResult })(
  SemisterResultPrint
);
