import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  GET_ACTIVITY,
  GET_ACTIVITY_DETAILS,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET ACTIVITY LIST
export const getActivityList = () => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/activity`);

    dispatch({
      type: GET_ACTIVITY,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

//GET ACTIVITY Details
export const getActivityDetails = (id) => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/activity/${id}/details`);

    dispatch({
      type: GET_ACTIVITY_DETAILS,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE ACTIVITY
export const createActivity = (values) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/activity`,
      JSON.stringify({ ...values }),
      config
    );
    dispatch({
      type: CREATE_ACTIVITY,
      payload: res.data.data,
    });
    toast.success("Activity added successfully");

    dispatch(getActivityList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Activity
export const deleteActivity = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/activity/${id}`);
    dispatch({
      type: DELETE_ACTIVITY,
      payload: id,
    });
    dispatch(getActivityList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
