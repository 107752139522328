import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Card,
  Container,
  Form as BootstrapForm,
  InputGroup,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Switch } from "@mantine/core";
import { createActivity } from "../../actions/Activity.action";
import styles from "./AddActivityForm.module.css";

const AddActivityForm = ({ createActivity, data, edit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmitHandeler = async (values) => {
    if (values.gradeId === "none") {
      toast.error("Select a Grade!");
      return false;
    }
    setIsLoading(true);
    let check = await createActivity(values);
    if (check === true) {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/admin/extra");
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  let initVals = {
    name: edit === true && data ? data.name : "",
    dropdown: edit === true && data ? data.dropdown : true,
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Subject Name is required!"),
  });
  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <h1 className="fs-4 fw-normal py-3">
            Fill the form to {edit ? "edit" : "add"} a activity
          </h1>
          <Formik
            initialValues={initVals}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => onSubmitHandeler(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <label htmlFor="name" className="d-block">
                      Name
                    </label>
                    {errors.name && touched.name ? (
                      <small className="text-danger pt-2">{errors.name}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type name of the subject..."
                    name="name"
                    isValid={!errors.name && touched.name}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.name && touched.name}
                  />
                </InputGroup>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <label htmlFor="dropdown" className="d-block">
                      Is Dropdown?
                    </label>
                    {errors.dropdown && touched.dropdown ? (
                      <small className="text-danger pt-2">
                        {errors.dropdown}
                      </small>
                    ) : null}
                  </div>
                  <Switch
                    checked={values.dropdown}
                    onChange={(event) =>
                      setFieldValue("dropdown", event.currentTarget.checked)
                    }
                  />
                </InputGroup>

                <div className="pt-4">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn_primary"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "Loading..."
                      : edit
                      ? "Edit Activity"
                      : "Add Activity"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default connect(null, {
  createActivity,
})(AddActivityForm);
