import React from "react";
import { connect } from "react-redux";
import { FullSemisterForm } from "../../components/FullSemisterForm";
import FullSemisterResultView from "../../components/FullSemisterResultView/FullSemisterResultView";
import Layout from "../../components/shared/Layout/Layout";

const FullSemisterResultPage = ({ data }) => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Full Semister Result">
        <FullSemisterForm />
        {data ? <FullSemisterResultView data={data} /> : <></>}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.mark.full_semister,
});

export default connect(mapStateToProps, null)(FullSemisterResultPage);
