import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getGradeList } from "../../actions/Grade.action";
import { getFullFinalTopResult } from "../../actions/Semister.action";

const FullSemisterTopForm = ({
  getFullFinalTopResult,
  grades,
  getGradeList,
}) => {
  const [roll, setRoll] = useState("");
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());

  const navigate = useNavigate();

  useEffect(() => {
    if (grades === null) {
      getGradeList();
    }
  }, []);

  const selectHandeler = async () => {
    if (!year || !grade) {
      toast.error("Please select all field!");
      return;
    } else {
      let check = await getFullFinalTopResult(year, grade, shift, batch);
    }
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow mb-4`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
                value={year}
              >
                <option value={""}>Select Year</option>
                {Array.from({ length: 20 }, (_, i) => i + 2010)
                  .reverse()
                  .map((item, i) => (
                    <option key={i} value={`${item}`}>
                      {item}
                    </option>
                  ))}
              </Form.Select>
            </Col>

            <Col
              md={3}
              className="d-none d-md-flex jsutify-content-end align-items-end py-3"
            >
              {year && grade ? (
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" ? (
              <Col
                md={3}
                className="d-md-none d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.student.student,
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  getFullFinalTopResult,
  getGradeList,
})(FullSemisterTopForm);
