import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getActivityList } from "../../actions/Activity.action";
import { getSavedList } from "../../actions/Saved.action";
import { IndividualSemisterResult } from "../../components/IndividualSemisterResult";
import RemarkSelector from "../../components/RemarkSelector/RemarkSelector";
import SemisterResultActivitySelector from "../../components/SemisterResultActivitySelector/SemisterResultActivitySelector";
import SemisterResultPrint from "../../components/SemisterResultPrint/SemisterResultPrint";
import Layout from "../../components/shared/Layout/Layout";

const SemisterResultPrintPage = ({
  data,
  activityData,
  getActivityList,
  getSavedList,
}) => {
  const [activities, setActivities] = useState([]);
  const [remark, setRemark] = useState("A");
  useEffect(() => {
    if (data && data?.semister?.length > 0 && data?.student) {
      getSavedList(data?.semister[0].id, data?.student?.id);
    }
    if (!activityData) {
      getActivityList();
    }
  }, [data?.student?.id]);

  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Semister Result Print">
        <IndividualSemisterResult />
        {data ? (
          <>
            <SemisterResultPrint
              data={data}
              remark={remark}
              activities={activities}
            />
            <SemisterResultActivitySelector
              activities={activities}
              setActivities={setActivities}
              activityData={activityData}
              data={data}
            />
            <RemarkSelector remark={remark} setRemark={setRemark} />
          </>
        ) : (
          <></>
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.mark.term,
  activityData: state.activity.activity,
});

export default connect(mapStateToProps, { getActivityList, getSavedList })(
  SemisterResultPrintPage
);
