import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_MARK,
  DELETE_MARK,
  GET_MARK,
  GET_GRADE_MARK,
  UPDATE_MARK,
  GET_STUDENT_MARK,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET MARK LIST
export const getMarkList =
  (batchId, year, month, gradeId, shiftId, subjectId, present) =>
  async (dispatch) => {
    let data = {
      batchId,
      year,
      month,
      gradeId,
      shiftId,
      subjectId,
    };

    if (present && present === true) {
      data = { ...data, present: true };
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/mark/batch`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_MARK,
        payload: res.data,
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
//GET GRADE MARK LIST
export const getMarkListForPerformanceGrade =
  (year, month, gradeId, shiftId, batchId, subjectId) => async (dispatch) => {
    const data = {
      year,
      month,
      gradeId,
      shiftId,
      batchId,
      subjectId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/mark/grade`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_MARK,
        payload: res.data,
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
//GET MARK LIST
export const getStudentMarkList = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/mark/student/${id}`);
    // console.log(res);

    dispatch({
      type: GET_STUDENT_MARK,
      payload: res.data.data,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//GET MARK LIST MARK UID
export const getMarkListByUID = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/mark/uid/${id}`);
    // console.log(res);

    dispatch({
      type: GET_STUDENT_MARK,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE MARK
export const createMark =
  (month, year, batchId, students, working_day, grade, shift, subject) =>
  async (dispatch) => {
    let formData = {
      month,
      year,
      batchId,
      marks: [],
      working_day: parseInt(working_day),
      shiftId: shift,
      gradeId: grade,
      subjectId: subject,
    };

    for (let i = 0; i < students.length; i++) {
      formData.marks.push({
        studentId: students[i].studentId,
        base: students[i].base,
        total: students[i].total,
        present: students[i].present,
      });
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // TODO ::: API CALL
      const res = await axios.post(
        `${BASE_URL}/api/mark`,
        JSON.stringify(formData),
        config
      );
      dispatch({
        type: CREATE_MARK,
        payload: res.data.data,
      });
      toast.success("Mark added successfully");

      return true;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };

// CREATE MARK
export const updateMark =
  (
    month,
    year,
    batchId,
    students,
    working_day,
    grade,
    shift,
    base,
    subjectId
  ) =>
  async (dispatch) => {
    let formData = {
      month,
      year,
      batchId,
      marks: [],
      working_day: parseInt(working_day),
      shiftId: shift,
      gradeId: grade,
      base: base,
      subjectId,
    };

    for (let i = 0; i < students.length; i++) {
      formData.marks.push({
        id: students[i].id,
        studentId: students[i].studentId,
        base: base,
        total: students[i].total,
        present: students[i].present,
      });
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // TODO ::: API CALL
      const res = await axios.patch(
        `${BASE_URL}/api/mark`,
        JSON.stringify(formData),
        config
      );
      dispatch({
        type: UPDATE_MARK,
        payload: res.data.data,
      });
      toast.success("Mark edited successfully");

      return true;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };
//DELETE Mark
export const deleteMark = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/mark/${id}`);
    dispatch({
      type: DELETE_MARK,
      payload: id,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
