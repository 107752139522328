import React from "react";
import { Container } from "react-bootstrap";

import { connect } from "react-redux";
import { IndividualSemisterResult } from "../../components/IndividualSemisterResult";
import SemisterResultView from "../../components/SemisterResultView/SemisterResultView";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicViewSemister = ({ data }) => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={`Semister Result`}>
        <IndividualSemisterResult hide={true} />
        <Container className="mb-5">
          {data ? <SemisterResultView data={data} /> : <></>}
        </Container>
      </PublicLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.mark.term,
});

export default connect(mapStateToProps, null)(PublicViewSemister);
