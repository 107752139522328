import { GET_SUBJECT, GET_SUBJECT_DETAILS } from "../constants/Type";

const initialState = {
  subject: null,
  selected_subject: null,
  loading: true,
};

const subjectReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBJECT:
      return {
        ...state,
        subject: payload,
        loading: false,
      };
    case GET_SUBJECT_DETAILS:
      return {
        ...state,
        selected_subject: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default subjectReducer;
