const getMonthsForTerm = (term) => {
  let months = [0, 1, 2];
  if (term === "2nd Term") {
    months = [4, 5, 6];
  } else if (term === "3rd Term") {
    months = [8, 9, 10];
  }

  return months;
};

export default getMonthsForTerm;
