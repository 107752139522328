import { Routes, Route, BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AddMarkPage, DashboardPage, LoginPage, MarkListPage } from "./views";
import { ToastContainer } from "react-toastify";
import PrivateOutlet from "./utils/PrivateOutlet";
import { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import setAuthToken from "./utils/setAuthToken";
import AddGradePage from "./views/AddGradePage/AddGradePage";
import GradeListPage from "./views/GradeListPage/GradeListPage";
import ShiftListPage from "./views/ShiftListPage/ShiftListPage";
import AddShiftPage from "./views/AddShiftPage/AddShiftPage";
import BatchListPage from "./views/BatchListPage/BatchListPage";
import AddBatchPage from "./views/AddBatchPage/AddBatchPage";
import StudentListPage from "./views/StudentListPage/StudentListPage";
import AddStudentPage from "./views/AddStudentPage/AddStudentPage";
import MarkListStudentPage from "./views/MarkListStudentPage/MarkListStudentPage";
import EditMarkPage from "./views/EditMarkPage/EditMarkPage";
import ViewResultPage from "./views/ViewResultPage/ViewResultPage";
import AlarmingStudentPage from "./views/AlarmingStudentPage/AlarmingStudentPage";
import AcademicGraphPage from "./views/AcademicGraphPage/AcademicGraphPage";
import CompariosnGraph from "./views/CompariosnGraph/CompariosnGraph";
import IndividualResult from "./views/IndividualResult/IndividualResult";
import LandingPage from "./views/LandingPage/LandingPage";
import PublicIndividualResult from "./views/PublicIndividualResult/IndividualResult";
import PublicMarkListStudentPage from "./views/PublicMarkListStudentPage/PublicMarkListStudentPage";
import PublicMarkListPage from "./views/PublicMarkListPage/PublicMarkListPage";
import PublicAlarmingStudentPage from "./views/PublicAlarmingStudentPage/PublicAlarmingStudentPage";
import PublicCompariosnGraphPage from "./views/PublicCompariosnGraphPage/PublicCompariosnGraphPage";
import PublicAcademicGraphPage from "./views/PublicAcademicGraphPage/PublicAcademicGraphPage";
import ClassPerformancePage from "./views/ClassPerformancePage/ClassPerformancePage";
import ClassSummaryPage from "./views/ClassSummaryPage/ClassSummaryPage";
import SubjectListPage from "./views/SubjectListPage/SubjectListPage";
import AddSubjectPage from "./views/AddSubjectPage/AddSubjectPage";
import AttandanceBatchPage from "./views/AttandanceBatchPage/AttandanceBatchPage";
import BatchOverviewPage from "./views/BatchOverviewPage/BatchOverviewPage";
import AddSemisterPage from "./views/AddSemisterPage/AddSemisterPage";
import SemisterResultPage from "./views/SemisterResultPage/SemisterResultPage";
import EditSemisterPage from "./views/EditSemisterPage/EditSemisterPage";
import PublicTopResult from "./views/PublicTopResult/PublicTopResult";
import MarkListStudentMonthlyPage from "./views/MarkListStudentMonthlyPage/MarkListStudentMonthlyPage";
import TopSemisterResultPage from "./views/TopSemisterResultPage/TopSemisterResultPage";
import IndividualResultInd from "./views/IndividualResultInd/IndividualResultInd";
import ExtraListPage from "./views/ExtraListPage/ExtraListPage";
import AddExtraPage from "./views/AddExtraPage/AddExtraPage";
import SemisterResultPrintPage from "./views/SemisterResultPrintPage/SemisterResultPrintPage";
import PublicTopSemisterResult from "./views/PublicTopSemister/PublicTopResult";
import PublicViewSemister from "./views/PublicViewSemister/PublicViewSemister";
import FullSemisterResultPage from "./views/FullSemisterResultPage/FullSemisterResultPage";
import FullSemisterTopResultPage from "./views/FullSemisterTopResultPage/FullSemisterTopResultPage";
import IndividualResultIndPublic from "./views/IndividualResultIndPublic/IndividualResultIndPublic";
import PublicMarkListStudentMonthlyPage from "./views/PublicMarkListStudentMonthlyPage/PublicMarkListStudentMonthlyPage";
import SemisterSummaryPage from "./views/SemisterSummaryPage/SemisterSummaryPage";
import PublicFullSemisterResultPage from "./views/PublicFullSemisterResultPage/PublicFullSemisterResultPage";
import PublicMeritListPage from "./views/PublicMeritListPage/PublicMeritListPage";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("token_coaching")) {
      setAuthToken(localStorage.getItem("token_coaching"));
    }
    loadingHandeler();

    return () => {
      setLoading(false);
    };
  }, [loading]);

  const loadingHandeler = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  return (
    <>
      {loading ? (
        <div className={`landing_loader`}>
          <span className="d-block text-light fs-4 py-4">Loading...</span>
          <ScaleLoader color={"#f68a3c"} loading={loading} size={150} />
        </div>
      ) : (
        <></>
      )}
      <ToastContainer newestOnTop theme="dark" />
      <BrowserRouter>
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/view-result" element={<PublicMarkListPage />} />
          <Route
            path="/full-semister"
            element={<PublicFullSemisterResultPage />}
          />
          <Route path="/top-result" element={<PublicTopResult />} />
          <Route path="/monthly-form" element={<IndividualResultIndPublic />} />
          <Route
            path="/individual/:id"
            element={<PublicMarkListStudentMonthlyPage />}
          />
          <Route
            path="/alarming-students"
            element={<PublicAlarmingStudentPage />}
          />
          <Route path="/academic-graph" element={<PublicAcademicGraphPage />} />
          <Route path="/compare" element={<PublicCompariosnGraphPage />} />
          <Route
            path="/individual-result"
            element={<PublicIndividualResult />}
          />
          <Route path="/semister-top" element={<PublicTopSemisterResult />} />
          <Route path="/merit-list" element={<PublicMeritListPage />} />
          <Route path="/semister" element={<PublicViewSemister />} />

          <Route path="/student/:id" element={<PublicMarkListStudentPage />} />

          <Route path="/*" element={<PrivateOutlet />}>
            <>
              {/* PRIVATE ROUTES */}
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="grade/add" element={<AddGradePage />} />
              <Route
                path="admin/class-performance/"
                element={<ClassPerformancePage />}
              />
              <Route
                path="admin/attendence-batch"
                element={<AttandanceBatchPage />}
              />
              <Route
                path="admin/overview-batch"
                element={<BatchOverviewPage />}
              />
              <Route
                path="admin/class-summary"
                element={<ClassSummaryPage />}
              />
              <Route
                path="admin/class-performance/:type"
                element={<ClassPerformancePage />}
              />
              <Route
                path="grade/:id/edit"
                element={<AddGradePage edit={true} />}
              />
              <Route path="grade" element={<GradeListPage />} />
              <Route path="admin/shift" element={<ShiftListPage />} />
              <Route path="admin/subject" element={<SubjectListPage />} />
              <Route path="admin/subject/add" element={<AddSubjectPage />} />
              <Route path="admin/extra" element={<ExtraListPage />} />
              <Route path="admin/extra/add" element={<AddExtraPage />} />
              <Route
                path="admin/subject/:id/edit"
                element={<AddSubjectPage edit={true} />}
              />
              <Route path="admin/shift/add" element={<AddShiftPage />} />
              <Route
                path="admin/shift/:id/edit"
                element={<AddShiftPage edit={true} />}
              />
              <Route path="admin/batch" element={<BatchListPage />} />
              <Route path="admin/batch/add" element={<AddBatchPage />} />
              <Route
                path="admin/batch/:id/edit"
                element={<AddBatchPage edit={true} />}
              />
              <Route path="admin/mark/add" element={<AddMarkPage />} />
              <Route
                path="admin/semister-top"
                element={<TopSemisterResultPage />}
              />
              <Route path="admin/semister" element={<SemisterResultPage />} />
              <Route
                path="admin/semister-print"
                element={<SemisterResultPrintPage />}
              />
              <Route
                path="admin/semister-summary"
                element={<SemisterSummaryPage />}
              />
              <Route path="admin/semister-add" element={<AddSemisterPage />} />
              <Route
                path="admin/semister-edit"
                element={<EditSemisterPage />}
              />
              <Route path="admin/mark/edit" element={<EditMarkPage />} />
              <Route
                path="admin/mark/student/:id"
                element={<MarkListStudentPage />}
              />
              <Route
                path="admin/individual/:id"
                element={<MarkListStudentMonthlyPage />}
              />
              <Route
                path="admin/mark/top"
                element={<MarkListPage top={true} />}
              />
              <Route path="admin/comparison" element={<CompariosnGraph />} />
              <Route path="admin/mark" element={<MarkListPage />} />
              <Route path="admin/view-result" element={<ViewResultPage />} />
              <Route
                path="admin/academic-graph"
                element={<AcademicGraphPage />}
              />
              <Route
                path="admin/individual-result"
                element={<IndividualResult />}
              />
              <Route
                path="admin/individual-result-ind"
                element={<IndividualResultInd />}
              />
              <Route
                path="admin/alarming-student"
                element={<AlarmingStudentPage />}
              />
              <Route path="admin/student" element={<StudentListPage />} />
              <Route path="admin/student/add" element={<AddStudentPage />} />
              <Route
                path="admin/student/:id/edit"
                element={<AddStudentPage edit={true} />}
              />
              <Route
                path="admin/semister-full"
                element={<FullSemisterResultPage />}
              />
              <Route
                path="admin/semister-full-top"
                element={<FullSemisterTopResultPage />}
              />
            </>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
