import React from "react";
import { IndividualResultForm } from "../../components/IndividualResultForm";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const IndividualResultIndPublic = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={`Monthly Basis Serial Result`}>
        <IndividualResultForm
          hide={true}
          publicView
          searchParams="individual"
        />
      </PublicLayout>
    </div>
  );
};

export default IndividualResultIndPublic;
