import React from "react";
import styles from "./SemisterResultPrint.module.css";

const ActivitiesList = ({ data }) => {
  return (
    <>
      {data ? (
        <div className={styles.act_list}>
          {data.length > 0 ? (
            data?.map((act, i) => (
              <div key={i} className={styles.act}>
                <span className="d-block ">
                  {act.name} :<span className="fw-bold ms-2"> {act.grade}</span>
                </span>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActivitiesList;
