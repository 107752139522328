import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";

import styles from "./RatioGraph.module.css";
import { connect } from "react-redux";
import { AiFillPrinter } from "react-icons/ai";
import { deleteGrade, getGradeList } from "../../actions/Grade.action";
import { months, years } from "../../constants/MonthsAndYears";
import { getMarkList } from "../../actions/Mark.action";
import { getGrade } from "../../utils/getGrade";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PiChart from "../PiChart/PiChart";
import { PiChartComparison } from "../PiChartComparison";

const RatioGraph = ({ list, getGradeList, grades, getMarkList }) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("0");

  const ref = useRef(null);

  useEffect(() => {
    if (grades === null) {
      getGradeList();
    }
    if (list !== null) {
      setRawList(list);
    }
  }, [list]);

  const selectHandeler = async () => {
    await getMarkList(batch, year, month, grade, shift);
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grades && grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={6} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="month" className="d-block">
                  Month
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                id="month"
                name="month"
              >
                {months.map((item, i) => (
                  <option key={i} value={`${i}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={6} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {rawList !== null ? (
        <Card className="crd p-md-2 pb-md-0 p-2 mt-4">
          <Card.Body>
            {rawList === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : rawList.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <>
                <div className="d-flex justify-content-end ">
                  <div className="">
                    <ReactToPrint
                      documentTitle={`Marksheet - ${rawList[0].batch.name} - ${
                        months[rawList[0].month]
                      } - ${rawList[0].year}`}
                      content={() => ref.current}
                    >
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <span
                            className={styles.chart_icon}
                            onClick={handlePrint}
                          >
                            <AiFillPrinter />
                          </span>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                  </div>
                </div>
                <div className="p-3" ref={ref}>
                  <span className="text-center fs-4 d-block ">Comparison</span>

                  <div className="d-flex align-items center justify-content-center">
                    <div className="d-flex flex-md-row flex-column">
                      <span className="d-block pe-3">
                        <span className="fw-bold">Normal Student</span> ={" "}
                        {
                          rawList.filter(
                            (l) =>
                              getGrade(l.percent) === "A++" ||
                              getGrade(l.percent) === "A+" ||
                              getGrade(l.percent) === "A"
                          ).length
                        }
                      </span>

                      <span className="d-block pe-3">
                        <span className="fw-bold">Alarming Student</span> ={" "}
                        {
                          rawList.filter(
                            (l) =>
                              getGrade(l.percent) === "A-" ||
                              getGrade(l.percent) === "B" ||
                              getGrade(l.percent) === "C"
                          ).length
                        }
                      </span>
                    </div>
                  </div>
                  {rawList !== null ? (
                    <PiChartComparison
                      app={
                        rawList.filter((l) => getGrade(l.percent) === "A++")
                          .length
                      }
                      ap={
                        rawList.filter((l) => getGrade(l.percent) === "A+")
                          .length
                      }
                      a={
                        rawList.filter((l) => getGrade(l.percent) === "A")
                          .length
                      }
                      am={
                        rawList.filter((l) => getGrade(l.percent) === "A-")
                          .length
                      }
                      b={
                        rawList.filter((l) => getGrade(l.percent) === "B")
                          .length
                      }
                      c={
                        rawList.filter((l) => getGrade(l.percent) === "C")
                          .length
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.mark.mark,
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  getGradeList,
  deleteGrade,
  getMarkList,
})(RatioGraph);
