import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Button,
  Card,
  InputGroup,
  Form as BootstrapForm,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import * as Yup from "yup";
import styles from "./AddStudentForm.module.css";
import { useNavigate } from "react-router-dom";
import { getGradeList } from "../../actions/Grade.action";
import { toast } from "react-toastify";
import { createStudent, updateStudent } from "../../actions/Student.action";
import { BASE_URL } from "../../constants/URL";

const AddStudentForm = ({
  createStudent,
  data,
  edit,
  updateStudent,
  getGradeList,
  grades,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (grades === null) {
      getGradeList();
    }
  }, []);
  const onSubmitHandeler = async (values) => {
    if (values.gradeId === "none") {
      toast.error("Select a Grade!");
      return false;
    }
    setIsLoading(true);
    let check = edit
      ? await updateStudent(values, data.id, image)
      : await createStudent(values, image);
    if (check === true) {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/admin/student");
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  let initVals = {
    name: edit === true && data ? data.name : "",
    year: edit === true && data ? data.year : "",
    phone: edit === true && data ? data.phone : "",
    uid: edit === true && data ? data.uid : "",
    gradeId: edit === true && data ? `${data.gradeId}` : "none",
    shiftId: edit === true && data ? `${data.shiftId}` : "none",
    batchId: edit === true && data ? `${data.batchId}` : "none",
    // PROFILE MODEL
    father: edit === true && data && data.father ? data?.father : "",
    mother: edit === true && data && data.mother ? data?.mother : "",
    address: edit === true && data && data.address ? data?.address : "",
    dob: edit === true && data && data.dob ? data?.dob.split("T")[0] : "",
    blood: edit === true && data && data.blood ? data?.blood : "",
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Student Name is required!"),
    phone: Yup.string().required("Phone is required!"),
    year: Yup.string().required("Student Year is required!"),
    uid: Yup.string().required("Student Roll is required!"),
    gradeId: Yup.string().required("Grade is required!"),
    shiftId: Yup.string()
      .test("val test", "Please select shift", (item) => {
        return item !== "none";
      })
      .required("Shift is required!"),
    batchId: Yup.string().required("Batch is required!"),
  });
  return (
    <Container className="pb-5">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <h1 className="fs-4 fw-normal py-3">
            Fill the form to {edit ? "edit" : "add"} student
          </h1>
          <Formik
            initialValues={initVals}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => onSubmitHandeler(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="name" className="d-block">
                      Name
                    </label>
                    {errors.name && touched.name ? (
                      <small className="text-danger pt-2">{errors.name}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type name of the shift..."
                    name="name"
                    isValid={!errors.name && touched.name}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.name && touched.name}
                  />
                </InputGroup>
                <Row>
                  <Col md={6}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="father" className="d-block">
                          Father's name
                        </label>
                        {errors.father && touched.father ? (
                          <small className="text-danger pt-2">
                            {errors.father}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type father's name of the student..."
                        name="father"
                        type="text"
                        className={`${styles.input} w-100`}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="mother" className="d-block">
                          Mother's name
                        </label>
                        {errors.mother && touched.mother ? (
                          <small className="text-danger pt-2">
                            {errors.mother}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type mother's name of the student..."
                        name="mother"
                        type="text"
                        className={`${styles.input} w-100`}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="address" className="d-block">
                          Living place
                        </label>
                        {errors.address && touched.address ? (
                          <small className="text-danger pt-2">
                            {errors.address}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type living place of the student..."
                        name="address"
                        type="text"
                        className={`${styles.input} w-100`}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="blood" className="d-block">
                          Blood Group
                        </label>
                        {errors.blood && touched.blood ? (
                          <small className="text-danger pt-2">
                            {errors.blood}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type blood's group of the student..."
                        name="blood"
                        type="text"
                        className={`${styles.input} w-100`}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="dob" className="d-block">
                          Date of birth
                        </label>
                        {errors.dob && touched.dob ? (
                          <small className="text-danger pt-2">
                            {errors.dob}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Select date of birth of the student..."
                        name="dob"
                        type="date"
                        className={`${styles.input} w-100`}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={8}>
                    {" "}
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex  ">
                        <label htmlFor="image" className="d-block">
                          Image
                        </label>
                      </div>{" "}
                      <BootstrapForm.Control
                        type="file"
                        onChange={(e) =>
                          setImage(
                            e.target?.files[0] ? e.target.files[0] : null
                          )
                        }
                        className={`mt-1 w-100`}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="phone" className="d-block">
                          Phone
                        </label>
                        {errors.phone && touched.phone ? (
                          <small className="text-danger pt-2">
                            {errors.phone}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type phone number..."
                        name="phone"
                        isValid={!errors.phone && touched.phone}
                        type="text"
                        className={`${styles.input} w-100`}
                        isInvalid={errors.phone && touched.phone}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="year" className="d-block">
                          Student Year
                        </label>
                        {errors.year && touched.year ? (
                          <small className="text-danger pt-2">
                            {errors.year}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type year of the student..."
                        name="year"
                        isValid={!errors.year && touched.year}
                        type="text"
                        className={`${styles.input} w-100`}
                        isInvalid={errors.year && touched.year}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="uid" className="d-block">
                          Student Roll
                        </label>
                        {errors.uid && touched.uid ? (
                          <small className="text-danger pt-2">
                            {errors.uid}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type roll of the student..."
                        name="uid"
                        isValid={!errors.uid && touched.uid}
                        type="text"
                        className={`${styles.input} w-100`}
                        isInvalid={errors.uid && touched.uid}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="gradeId" className="d-block">
                          Class
                        </label>
                        {errors.gradeId && touched.gradeId ? (
                          <small className="text-danger pt-2">
                            {errors.gradeId}
                          </small>
                        ) : null}
                      </div>
                      {grades ? (
                        <BootstrapForm.Select
                          gradeId="gradeId"
                          isValid={!errors.gradeId && touched.gradeId}
                          className={`${styles.input} w-100`}
                          isInvalid={errors.gradeId && touched.gradeId}
                          onChange={(e) => {
                            setFieldValue("gradeId", e.target.value);
                            setFieldValue("shiftId", "none");
                            setFieldValue("batchId", "none");
                          }}
                          value={values.gradeId}
                        >
                          <option value="none"> Select Class</option>
                          {grades
                            .filter((g) => g.shifts.length > 0)
                            .map((grd) => (
                              <option value={grd.id} key={grd.id}>
                                {grd.name}
                              </option>
                            ))}
                        </BootstrapForm.Select>
                      ) : (
                        <></>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {" "}
                    {values.gradeId && values.gradeId !== "none" ? (
                      <InputGroup className="mb-3 d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-center">
                          <label htmlFor="shiftId" className="d-block">
                            Shift
                          </label>
                          {errors.shiftId && touched.shiftId ? (
                            <small className="text-danger pt-2">
                              {errors.shiftId}
                            </small>
                          ) : null}
                        </div>
                        {grades ? (
                          <BootstrapForm.Select
                            id="shiftId"
                            isValid={!errors.shiftId && touched.shiftId}
                            className={`${styles.input} w-100`}
                            isInvalid={errors.shiftId && touched.shiftId}
                            onChange={(e) => {
                              setFieldValue("shiftId", e.target.value);
                              setFieldValue("batchId", "none");
                            }}
                            value={values.shiftId}
                          >
                            <option value="none"> Select Shift</option>
                            {grades
                              .filter(
                                (it) => it.id === parseInt(values.gradeId)
                              )[0]
                              .shifts?.filter(
                                (g) =>
                                  grades
                                    .filter(
                                      (it) => it.id === parseInt(values.gradeId)
                                    )[0]
                                    .batches.filter((bt) => bt.shiftId === g.id)
                                    .length > 0
                              )
                              .map((grd) => (
                                <option value={grd.id} key={grd.id}>
                                  {grd.name}
                                </option>
                              ))}
                          </BootstrapForm.Select>
                        ) : (
                          <></>
                        )}
                      </InputGroup>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col md={6}>
                    {values.gradeId &&
                    values.gradeId !== "none" &&
                    values.shiftId &&
                    values.shiftId !== "none" ? (
                      <InputGroup className="mb-3 d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-center">
                          <label htmlFor="batchId" className="d-block">
                            Batch
                          </label>
                          {errors.batchId && touched.batchId ? (
                            <small className="text-danger pt-2">
                              {errors.batchId}
                            </small>
                          ) : null}
                        </div>
                        {grades ? (
                          <BootstrapForm.Select
                            id="batchId"
                            isValid={!errors.batchId && touched.batchId}
                            className={`${styles.input} w-100`}
                            isInvalid={errors.batchId && touched.batchId}
                            onChange={(e) =>
                              setFieldValue("batchId", e.target.value)
                            }
                            value={values.batchId}
                          >
                            <option value="none"> Select Batch</option>
                            {grades
                              .filter(
                                (it) => it.id === parseInt(values.gradeId)
                              )[0]
                              .batches?.filter(
                                (bt) => bt.shiftId === parseInt(values.shiftId)
                              )
                              .map((grd) => (
                                <option value={grd.id} key={grd.id}>
                                  {grd.name}
                                </option>
                              ))}
                          </BootstrapForm.Select>
                        ) : (
                          <></>
                        )}
                      </InputGroup>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>

                {edit && data && data?.image && data.image !== "" ? (
                  <img
                    src={`${BASE_URL}/api/uploads/${data.image}`}
                    style={{ maxHeight: 200, maxWidth: 400 }}
                    alt=""
                  />
                ) : (
                  <></>
                )}

                <div className="pt-4">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn_primary"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "Loading..."
                      : edit
                      ? "Edit Student"
                      : "Add Student"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  createStudent,
  updateStudent,
  getGradeList,
})(AddStudentForm);
