import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import { AiFillPrinter } from "react-icons/ai";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getGradeList } from "../../actions/Grade.action";
import { getMarkListForPerformanceGrade } from "../../actions/Mark.action";
import { months, years } from "../../constants/MonthsAndYears";
import { getGrade } from "../../utils/getGrade";
import SectionHeading from "../SectionHeading/SectionHeading";
import styles from "./ClassSummary.module.css";

const ClassSummary = ({
  list,
  getGradeList,
  grades,
  extra,
  getMarkListForPerformanceGrade,
}) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("0");
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    getGradeList();
    let tempSubs = [];
    let tmpStudents = [];
    if (list !== null) {
      list.map((item) => {
        if (!tempSubs.includes(item.subject.name)) {
          tempSubs.push(item.subject.name);
        }
        if (
          tmpStudents.filter((st) => st.studentId === item.studentId).length ===
          0
        ) {
          tmpStudents.push({
            ...item,
            count: 1,
            total: item.total,
            down: item.present,
          });
        } else {
          tmpStudents = tmpStudents.map((st) =>
            st.studentId === item.studentId
              ? {
                  ...st,
                  total: st.total + item.total,
                  down: st.down + item.present,
                  percent: st.percent + item.percent,
                  count: st.count + 1,
                }
              : st
          );
        }
        // if (item.studentId === 188) console.log(item.present);
        return item;
      });
      setSubjectList([...tempSubs]);
      // console.log(tmpStudents);
      setRawList(tmpStudents);
    }
  }, [list]);

  const selectHandeler = async () => {
    await getMarkListForPerformanceGrade(
      year,
      month,
      grade,
      shift,
      batch,
      subject
    );
  };

  const getClass = (data) => {
    let classes = [];
    for (let i = 0; i < data.length; i++) {
      if (classes.filter((cls) => cls.id === data[i].gradeId).length === 0) {
        classes.push({
          id: data[i].gradeId,
          grade: data[i].grade.name,
        });
      }
    }
    return { classes };
  };
  const getBatches = (data) => {
    let batches = [];
    for (let i = 0; i < data.length; i++) {
      if (batches.filter((cls) => cls.id === data[i].batchId).length === 0) {
        batches.push({
          id: data[i].batchId,
          batch: data[i].batch.name,
          shift: data[i].shift.name,
        });
      }
    }
    return { batches };
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grades && grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            <Col
              md={3}
              className="d-flex jsutify-content-end align-items-end py-3"
            >
              <Button
                onClick={selectHandeler}
                variant="primary"
                type="submit"
                className="btn_primary"
              >
                Select
              </Button>
            </Col>
          </Row>
          <Row>
            {grade !== "" ? (
              <Col md={4} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="subject" className="d-block">
                    Subject
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                >
                  <option value={""}>Select Subject</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .subjects.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="month" className="d-block">
                  Month
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                id="month"
                name="month"
              >
                {months.map((item, i) => (
                  <option key={i} value={`${i}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                value={year}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {rawList !== null ? (
        <Card className="crd p-md-2 pb-md-0 p-2 mt-4">
          <Card.Body>
            {rawList === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : rawList.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <>
                <div className="d-flex justify-content-end ">
                  <div className="">
                    <ReactToPrint
                      documentTitle={`Marksheet - ${rawList[0].batch.name} - ${
                        months[rawList[0].month]
                      } - ${rawList[0].year}`}
                      content={() => ref.current}
                    >
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <span
                            className={styles.chart_icon}
                            onClick={handlePrint}
                          >
                            <AiFillPrinter />
                          </span>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                  </div>
                </div>
                <div className="p-3" ref={ref}>
                  <SectionHeading
                    title={`Monthly Class Summary`}
                    year={rawList[0].year}
                    month={months[rawList[0].month]}
                    grade={extra?.gradeId}
                    shift={extra?.shiftId}
                    batch={extra?.batchId}
                  />
                  {/* <span className="text-center fs-4 d-block ">
                    Class Summary
                  </span>
                  <span className="text-center fs-6 fw-bold d-block">
                    {rawList !== null
                      ? ` ${months[rawList[0].month]} - ${rawList[0].year}`
                      : ""}
                  </span> */}

                  <div className="d-flex align-items center justify-content-center pb-3">
                    <div className="d-flex justify-content-center">
                      <span className="d-block pe-3">
                        <span className="fw-bold">A++</span> = (91-100)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A+</span> = (81-90)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A</span> = (71-80)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A-</span> = (61-70)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">B</span> = (51-60)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">C</span> = {"(<51)"}{" "}
                      </span>
                    </div>
                  </div>

                  {rawList !== null &&
                    getClass(rawList).classes.map((markItem, i) => (
                      <div key={markItem.id}>
                        <span className="d-block fs-4 fw-bold pt-3 pb-2">
                          Class: {markItem.grade}
                        </span>

                        <Table bordered responsive striped>
                          <thead>
                            <tr>
                              <th>Batch</th>
                              <th>Total Student</th>
                              <th>A++</th>
                              <th>A+ </th>
                              <th>A </th>
                              <th>A- </th>
                              <th>B </th>
                              <th>C </th>
                            </tr>
                          </thead>
                          <tbody>
                            {getBatches(
                              rawList.filter((rw) => rw.gradeId === markItem.id)
                            ).batches.map((btch) => (
                              <tr key={btch.id}>
                                <td>
                                  {btch.batch} - {btch.shift}
                                </td>
                                <td>
                                  {
                                    rawList.filter(
                                      (rw) => rw.batchId === btch.id
                                    ).length
                                  }
                                </td>
                                <td>
                                  {(
                                    (rawList
                                      .filter((rw) => rw.batchId === btch.id)
                                      .filter(
                                        (l) =>
                                          getGrade(
                                            (l.total * 100) / (l.base * l.down)
                                          ) === "A++"
                                      ).length *
                                      100) /
                                    rawList.filter(
                                      (rw) => rw.batchId === btch.id
                                    ).length
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td>
                                  {(
                                    (rawList
                                      .filter((rw) => rw.batchId === btch.id)
                                      .filter(
                                        (l) =>
                                          getGrade(
                                            (l.total * 100) / (l.base * l.down)
                                          ) === "A+"
                                      ).length *
                                      100) /
                                    rawList.filter(
                                      (rw) => rw.batchId === btch.id
                                    ).length
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td>
                                  {(
                                    (rawList
                                      .filter((rw) => rw.batchId === btch.id)
                                      .filter(
                                        (l) =>
                                          getGrade(
                                            (l.total * 100) / (l.base * l.down)
                                          ) === "A"
                                      ).length *
                                      100) /
                                    rawList.filter(
                                      (rw) => rw.batchId === btch.id
                                    ).length
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td>
                                  {(
                                    (rawList
                                      .filter((rw) => rw.batchId === btch.id)
                                      .filter(
                                        (l) =>
                                          getGrade(
                                            (l.total * 100) / (l.base * l.down)
                                          ) === "A-"
                                      ).length *
                                      100) /
                                    rawList.filter(
                                      (rw) => rw.batchId === btch.id
                                    ).length
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td>
                                  {(
                                    (rawList
                                      .filter((rw) => rw.batchId === btch.id)
                                      .filter(
                                        (l) =>
                                          getGrade(
                                            (l.total * 100) / (l.base * l.down)
                                          ) === "B"
                                      ).length *
                                      100) /
                                    rawList.filter(
                                      (rw) => rw.batchId === btch.id
                                    ).length
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td>
                                  {(
                                    (rawList
                                      .filter((rw) => rw.batchId === btch.id)
                                      .filter(
                                        (l) =>
                                          getGrade(
                                            (l.total * 100) / (l.base * l.down)
                                          ) === "C"
                                      ).length *
                                      100) /
                                    rawList.filter(
                                      (rw) => rw.batchId === btch.id
                                    ).length
                                  ).toFixed(2)}
                                  %
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="d-flex align-items-center flex-column justify-content-center">
                          <div className="d-flex flex-wrap">
                            <span className="d-block pe-3">
                              <span className="fw-bold">A++</span> ={" "}
                              {(
                                (rawList
                                  .filter((rw) => rw.gradeId === markItem.id)
                                  .filter(
                                    (l) =>
                                      getGrade(
                                        (l.total * 100) / (l.base * l.down)
                                      ) === "A++"
                                  ).length *
                                  100) /
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                ).length
                              ).toFixed(2)}
                              %
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">A+</span> ={" "}
                              {(
                                (rawList
                                  .filter((rw) => rw.gradeId === markItem.id)
                                  .filter(
                                    (l) =>
                                      getGrade(
                                        (l.total * 100) / (l.base * l.down)
                                      ) === "A+"
                                  ).length *
                                  100) /
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                ).length
                              ).toFixed(2)}
                              %
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">A</span> ={" "}
                              {(
                                (rawList
                                  .filter((rw) => rw.gradeId === markItem.id)
                                  .filter(
                                    (l) =>
                                      getGrade(
                                        (l.total * 100) / (l.base * l.down)
                                      ) === "A"
                                  ).length *
                                  100) /
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                ).length
                              ).toFixed(2)}
                              %
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">A-</span> ={" "}
                              {(
                                (rawList
                                  .filter((rw) => rw.gradeId === markItem.id)
                                  .filter(
                                    (l) =>
                                      getGrade(
                                        (l.total * 100) / (l.base * l.down)
                                      ) === "A-"
                                  ).length *
                                  100) /
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                ).length
                              ).toFixed(2)}
                              %
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">B</span> ={" "}
                              {(
                                (rawList
                                  .filter((rw) => rw.gradeId === markItem.id)
                                  .filter(
                                    (l) =>
                                      getGrade(
                                        (l.total * 100) / (l.base * l.down)
                                      ) === "B"
                                  ).length *
                                  100) /
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                ).length
                              ).toFixed(2)}
                              %
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">C</span> ={" "}
                              {(
                                (rawList
                                  .filter((rw) => rw.gradeId === markItem.id)
                                  .filter(
                                    (l) =>
                                      getGrade(
                                        (l.total * 100) / (l.base * l.down)
                                      ) === "C"
                                  ).length *
                                  100) /
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                ).length
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                          <div className="pt-3">
                            Total Students:{" "}
                            <span className="fw-bold">
                              {
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                ).length
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.mark.mark,
  grades: state.grade.grade,
  extra: state.mark.extra,
});

export default connect(mapStateToProps, {
  getGradeList,
  getMarkListForPerformanceGrade,
})(ClassSummary);
