import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";
import { AiFillPrinter } from "react-icons/ai";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getStudentMarkList } from "../../actions/Mark.action";
import { getStudentDetails } from "../../actions/Student.action";
import { months } from "../../constants/MonthsAndYears";
import { getGrade } from "../../utils/getGrade";
import { getMark } from "../../utils/getMark";
import LineChart from "../lineChartMonthly/lineChartMonthly";
import SectionHeading from "../SectionHeading/SectionHeading";
import styles from "./MarkListStudentMonthWise.module.css";

const MarkListStudentMonthWise = ({
  list,
  getStudentMarkList,
  id,
  getStudentDetails,
  student,
}) => {
  const ref = useRef(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    const getDataFunc = async () => {
      if (id !== null) {
        await getStudentDetails(id);

        await getStudentMarkList(id);
      }
    };
    getDataFunc();
  }, [id]);

  if (list && data === null) {
    let years = [];
    let tmpData = [];
    list.map((l) => {
      if (!years.includes(parseInt(l.year))) {
        years.push(parseInt(l.year));
        tmpData.push({
          year: parseInt(l.year),
          data: [],
        });
      }
      return l;
    });
    tmpData = tmpData.map((yr) => {
      return { ...yr, data: list.filter((f) => f.year === yr.year) };
    });

    tmpData = tmpData.map((tmp) => {
      let monthList = [];
      tmp.data.map((item) => {
        if (!monthList.filter((p) => p.month === item.month).length > 0) {
          monthList.push({
            month: item.month,
            count: 0,
            percent: 0,
            total: 0,
            down: 0,
            base: item.base,
          });
        }

        monthList = monthList.map((monthItem) => {
          return monthItem.month === item.month
            ? {
                ...monthItem,
                count: monthItem.count + 1,
                percent: monthItem.percent + item.percent,
                total: monthItem.total + item.total,
                down: monthItem.down + item.present,
                base: item.base,
              }
            : monthItem;
        });
        // console.log(monthList);
        return item;
      });

      return {
        ...tmp,
        final: monthList.sort(function (a, b) {
          if (a.month < b.month) return -1;
          if (a.month > b.month) return 1;
          return 0;
        }),
      };
    });
    setData(tmpData);
  }

  return (
    <Container className="pb-4">
      {list !== null && student !== null ? (
        <div className="d-flex justify-content-end">
          <ReactToPrint
            documentTitle={`Marksheet - ${student.name}`}
            content={() => ref.current}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <span className={styles.chart_icon} onClick={handlePrint}>
                  <AiFillPrinter />
                </span>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      ) : (
        <></>
      )}
      {student && student.id === parseInt(id) ? (
        <div className="">
          <span className="text-center fs-4 d-block pb-3">
            Student Information
          </span>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td className="fw-bold">Name</td>
                <td>{student.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Roll</td>
                <td>{student.uid}</td>
              </tr>
              <tr>
                <td className="fw-bold">Class</td>
                <td>{student.grade.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Shift</td>
                <td>{student.shift.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Batch</td>
                <td>{student.batch.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Phone</td>
                <td>{student.phone}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        <></>
      )}
      {list !== null && data !== null ? (
        <Card className="crd p-md-4 pb-md-0 p-2 mt-4" ref={ref}>
          <Card.Body>
            {list === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : list.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <>
                {list &&
                  data.map((yearData, i) => (
                    <Fragment key={i}>
                      <SectionHeading
                        title={`Monthly Individual Class Performance Report`}
                        subtitle={`Year : ${yearData.year}`}
                        grade={student?.gradeId}
                        shift={student?.shiftId}
                        batch={student?.batchId}
                      />
                      <Table striped bordered hover responsive className="mt-4">
                        <thead>
                          <tr>
                            <th>Month</th>
                            <th>Percent</th>
                            <th>Grade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {yearData.final.map((markItem, i) => (
                            <tr key={i}>
                              <td>
                                {months[markItem.month]}
                                {/* total ={" "}
                                {markItem.total} down ={" "}
                                {markItem.down * markItem.base} */}
                              </td>
                              <td>
                                {getMark(
                                  (markItem.total * 100) /
                                    (markItem.down * markItem.base)
                                )}
                                %
                              </td>
                              <td>
                                {getGrade(
                                  (markItem.total * 100) /
                                    (markItem.down * markItem.base)
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className={`${styles.chart} pt-4`}>
                        <LineChart data={yearData.final} />
                      </div>
                    </Fragment>
                  ))}
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.mark.student_mark,
  student: state.student.selected_student,
});

export default connect(mapStateToProps, {
  getStudentMarkList,
  getStudentDetails,
})(MarkListStudentMonthWise);
