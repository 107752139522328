import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getSubjectDetails } from "../../actions/Subject.action";
import { AddActivityForm } from "../../components/AddActivityForm";
import Layout from "../../components/shared/Layout/Layout";

const AddExtraPage = ({ edit, subject, getSubjectDetails }) => {
  const { id } = useParams();
  useEffect(() => {
    if (edit && id && !subject) {
      getSubjectDetails(id);
    }
  }, [id]);
  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} Extra Curricular Activitiy`}>
        {edit && id && subject ? (
          <AddActivityForm edit={true} data={subject} />
        ) : (
          <AddActivityForm />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subject: state.subject.selected_subject,
});

export default connect(mapStateToProps, { getSubjectDetails })(AddExtraPage);
