import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_SUBJECT,
  DELETE_SUBJECT,
  GET_SUBJECT,
  GET_SUBJECT_DETAILS,
  UPDATE_SUBJECT,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";
import { getGradeList } from "./Grade.action";

//GET SUBJECT LIST
export const getSubjectList = (id) => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/subject/${id}`);

    dispatch({
      type: GET_SUBJECT,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
//GET SUBJECT Details
export const getSubjectDetails = (id) => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/subject/${id}/details`);

    dispatch({
      type: GET_SUBJECT_DETAILS,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE SUBJECT
export const createSubject = (values) => async (dispatch) => {
  let formData = {
    name: values.name,
    gradeId: values.gradeId,
    mark: values.mark,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/subject`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: CREATE_SUBJECT,
      payload: res.data.data,
    });
    toast.success("Subject added successfully");

    dispatch(getGradeList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE SUBJECT
export const updateSubject = (values, id) => async (dispatch) => {
  let formData = {
    name: values.name,
    gradeId: values.gradeId,
    mark: values.mark,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/subject/${id}`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: UPDATE_SUBJECT,
      payload: res.data.data,
    });
    toast.success("Subject updated successfully");
    dispatch(getGradeList());
    dispatch(getSubjectDetails(id));
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Subject
export const deleteSubject = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/subject/${id}`);
    dispatch({
      type: DELETE_SUBJECT,
      payload: id,
    });
    dispatch(getGradeList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
