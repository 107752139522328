import React from "react";
import { connect } from "react-redux";
import { IndividualSemisterResult } from "../../components/IndividualSemisterResult";
import SemisterResultView from "../../components/SemisterResultView/SemisterResultView";
import Layout from "../../components/shared/Layout/Layout";

const SemisterResultPage = ({ data }) => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Semister Result">
        <IndividualSemisterResult />
        {data ? <SemisterResultView data={data} /> : <></>}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.mark.term,
});

export default connect(mapStateToProps, null)(SemisterResultPage);
