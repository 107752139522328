import React from "react";
import ExtraList from "../../components/ExtraList/ExtraList";
import Layout from "../../components/shared/Layout/Layout";

const ExtraListPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Extra Curricular Activities">
        <ExtraList />
      </Layout>
    </div>
  );
};

export default ExtraListPage;
