import React from "react";
import { connect } from "react-redux";
import { FullSemisterForm } from "../../components/FullSemisterForm";
import FullSemisterResultView from "../../components/FullSemisterResultView/FullSemisterResultView";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";
import { Container } from "react-bootstrap";

const PublicFullSemisterResultPage = ({ data }) => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title="Final Result">
        <FullSemisterForm />
        {data ? (
          <Container className="pb-5">
            <FullSemisterResultView data={data} />
          </Container>
        ) : (
          <></>
        )}
      </PublicLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.mark.full_semister,
});

export default connect(mapStateToProps, null)(PublicFullSemisterResultPage);
