import React, { useEffect, useRef } from "react";
import { Container, Table } from "react-bootstrap";
import { AiFillPrinter } from "react-icons/ai";
import Moment from "react-moment";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getGradeList } from "../../actions/Grade.action";
import { BASE_URL } from "../../constants/URL";
import { getGrade } from "../../utils/getGrade";
import { getMark } from "../../utils/getMark";
import SectionHeading from "../SemisterResultPrint/SectionHeading/SectionHeading";
import styles from "./FullSemisterResultView.module.css";

import logo from "../../Assets/logo.jpeg";

const FullSemisterResultView = ({ data, grade, getGradeList, list }) => {
  useEffect(() => {
    if (!grade) {
      getGradeList();
    }
  }, []);
  const ref = useRef(null);

  const termData = (term) => {
    if (term === 1) {
      term = "1st Term";
    } else if (term === 2) {
      term = "2nd Term";
    } else if (term === 3) {
      term = "3rd Term";
    }
    if (data && data.semister && data.semister.length > 0) {
      let t = 0;
      if (
        grade &&
        grade.filter((g) => g.id === data?.student?.gradeId).length > 0
      ) {
        grade
          .filter((g) => g.id === data?.student?.gradeId)[0]
          ?.subjects?.map((s) => {
            t += s.mark;
          });
      }

      return data.semister.filter((item) => item.term === term).length > 0
        ? data.semister.filter((item) => item.term === term)[0]
        : { total: t, gained: 0, term };
    } else {
      return { total: 0, gained: 0, term };
    }
  };

  const totalCalculaation = () => {
    let total = 0,
      gained = 0;
    if (data && data.semister && data.semister.length > 0) {
      total += parseFloat(getMark(termData(1).total * 0.25));
      total += parseFloat(getMark(termData(2).total * 0.25));
      total += parseFloat(getMark(termData(3).total * 0.5));
      gained += parseFloat(getMark(termData(1).gained * 0.25));
      gained += parseFloat(getMark(termData(2).gained * 0.25));
      gained += parseFloat(getMark(termData(3).gained * 0.5));

      return { total, gained };
    } else {
      return { total: 0, gained: 0 };
    }
  };

  return (
    <div className="card card-body shadow bg-white">
      <div className={`${styles.wrapper} d-flex justify-content-end `}>
        <div className="">
          <ReactToPrint
            documentTitle={`${data.term} Marksheet`}
            content={() => ref.current}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <span className={styles.chart_icon} onClick={handlePrint}>
                  <AiFillPrinter />
                </span>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      </div>
      {data && data.semister && data.semister.length > 0 ? (
        <div ref={ref} className="w-100">
          {data?.student ? (
            <div className="position-relative">
              {data?.student?.image ? (
                <>
                  <img src={logo} alt="logo" className={styles.logo} />
                  <div
                    className={styles.logo2}
                    style={{
                      background: `url(${BASE_URL}/api/uploads/${data?.student?.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                  ></div>
                </>
              ) : (
                <></>
              )}
              <SectionHeading
                noLogo={data?.student?.image}
                title={`Semister Result Analysis Part - ${data?.extra?.year}`}
                subtitle={`Student Information`}
                grade={data?.student?.gradeId}
                shift={data?.student?.shiftId}
                batch={data?.student?.batchId}
              />

              <Container fluid className="py-4">
                <div className={styles.std_info}>
                  <div className={styles.bordr_right}>
                    <div>
                      <div className="d-flex justify-content-center align-items-center ">
                        <span className={styles.info_heading}>Name : </span>

                        <span className={styles.info_text + " ms-1"}>
                          {data?.student.name}
                        </span>
                      </div>
                    </div>
                    {data?.student.address ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>
                            Address :{" "}
                          </span>

                          <span className={styles.info_text + " ms-1"}>
                            {data?.student.address}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {data?.student.blood ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>
                            Blood Group :{" "}
                          </span>

                          <span className={styles.info_text + " ms-1"}>
                            {data?.student.blood}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={styles.bordr_right}>
                    {data?.student.father ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Father : </span>

                          <span className={styles.info_text + " ms-1"}>
                            {data?.student.father}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {data?.student.dob ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>
                            Date of Birth :{" "}
                          </span>

                          <span className={styles.info_text + " ms-1"}>
                            <Moment format="DD MMMM YYYY">
                              {data?.student.dob}
                            </Moment>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div>
                      <div className="d-flex justify-content-center fw-bold align-items-center ">
                        <span className={styles.info_heading}>Roll : </span>

                        <span className={styles.info_text + " ms-1"}>
                          {data?.student.uid}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.bordr_right}>
                    {data?.student.mother ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Mother : </span>

                          <span className={styles.info_text + " ms-1"}>
                            {data?.student.mother}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {data?.student.phone ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Phone : </span>

                          <span className={styles.info_text + " ms-1"}>
                            {" "}
                            {data?.student.phone}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Container>
            </div>
          ) : (
            <></>
          )}
          <Table
            striped
            bordered
            hover
            responsive
            className={styles.table + " w-100"}
          >
            <thead>
              <tr>
                <th className="text-center">
                  First Semister <br /> Marks
                </th>
                <th className="text-center">
                  25% of First <br /> Semister Marks
                </th>
                <th className="text-center">
                  Second Semister <br /> Marks
                </th>
                <th className="text-center">
                  25% of Second <br /> Semister Marks
                </th>
                <th className="text-center">
                  Third Semister <br /> Marks
                </th>
                <th className="text-center">
                  50% of Third <br /> Semister Marks
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  {getMark(termData("1st Term").gained)}
                </td>
                <td className="text-center">
                  {getMark(termData("1st Term").gained * 0.25)}
                </td>
                <td className="text-center">
                  {getMark(termData("2nd Term").gained)}
                </td>
                <td className="text-center">
                  {getMark(termData("2nd Term").gained * 0.25)}
                </td>
                <td className="text-center">
                  {getMark(termData("3rd Term").gained)}
                </td>
                <td className="text-center">
                  {getMark(termData("3rd Term").gained * 0.5)}
                </td>
              </tr>
              <tr>
                <td colSpan={14} rowSpan={2}>
                  <div
                    className={`d-flex justify-content-center align-items-center py-4 ${styles.overall_info}`}
                  >
                    <span className="d-block">
                      <b>Total Mark = {getMark(totalCalculaation().total)}</b>
                    </span>
                    <span className="d-block ps-4">
                      <b>
                        Total Gained Mark ={" "}
                        {getMark(totalCalculaation().gained)}
                      </b>
                    </span>
                    <span className="d-block ps-4">
                      <b>
                        {" "}
                        Gained Percent ={" "}
                        {getMark(
                          (totalCalculaation().gained * 100) /
                            totalCalculaation().total
                        )}
                        %
                      </b>
                    </span>
                    <span className="d-block ps-4">
                      <b>
                        Overall Grade ={" "}
                        {getGrade(
                          getMark(
                            (totalCalculaation().gained * 100) /
                              totalCalculaation().total
                          )
                        )}
                      </b>
                    </span>
                    {list ? (
                      <span className="d-block ps-4">
                        <b>
                          Position ={" "}
                          {list.data
                            .sort(function (a, b) {
                              if (
                                parseFloat(getMark(a.percent)) ===
                                parseFloat(getMark(b.percent))
                              ) {
                                return parseInt(a.id) > parseInt(b.id) ? 1 : -1;
                              }
                              if (
                                parseFloat(getMark(a.percent)) <
                                parseFloat(getMark(b.percent))
                              )
                                return 1;
                              if (
                                parseFloat(getMark(a.percent)) >
                                parseFloat(getMark(b.percent))
                              )
                                return -1;
                              return 0;
                            })
                            .findIndex((el) => el.id === data?.student?.id) + 1}
                        </b>
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        <>
          <h3 className="text-center py-4">No semister result found</h3>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  grade: state.grade.grade,
  list: state.mark.full_semister_top,
});

export default connect(mapStateToProps, { getGradeList })(
  FullSemisterResultView
);
