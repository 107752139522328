import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getGradeList } from "../../actions/Grade.action";
import EditSemisterForm from "../../components/EditSemisterForm/EditSemisterForm";
import Layout from "../../components/shared/Layout/Layout";

const EditSemisterPage = ({ edit, grades, getGradeList }) => {
  useEffect(() => {
    getGradeList();
  }, []);
  return (
    <div className="bg_light">
      <Layout title={`Edit Semister Result`}>
        {grades ? <EditSemisterForm edit={true} grades={grades} /> : <></>}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(EditSemisterPage);
