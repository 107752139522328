import {
  DELETE_MARK,
  DELETE_SEMISTER,
  GET_BATCH_STUDENTS,
  GET_FULL_SEMISTER,
  GET_FULL_SEMISTER_TOP,
  GET_MARK,
  GET_MARK_DETAILS,
  GET_SEMISTER_DETAILS,
  GET_STUDENT_MARK,
  GET_STUDENT_TERM_RESULT,
  GET_TOP_SEMISTER,
  UPDATE_MARK,
} from "../constants/Type";

const initialState = {
  mark: null,
  extra: null,
  student_mark: null,
  students: null,
  selected_mark: null,
  loading: true,
  term: null,
  semister: null,
  top_semister: null,
  semister_extra: null,
  full_semister: null,
  full_semister_top: null,
};

const markReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MARK:
      return {
        ...state,
        mark: payload.data,
        extra: payload.extra,
        loading: false,
      };
    case GET_TOP_SEMISTER:
      return {
        ...state,
        top_semister: payload.data,
        semister_extra: payload.extra,
        loading: false,
      };
    case GET_STUDENT_TERM_RESULT:
      return {
        ...state,
        term: payload,
        loading: false,
      };
    case GET_FULL_SEMISTER:
      return {
        ...state,
        full_semister: payload,
        loading: false,
      };
    case GET_FULL_SEMISTER_TOP:
      return {
        ...state,
        full_semister_top: payload,
        loading: false,
      };
    case GET_SEMISTER_DETAILS:
      return {
        ...state,
        semister: payload,
        loading: false,
      };
    case DELETE_SEMISTER:
      return {
        ...state,
        semister: state.semister
          ? {
              ...state.semister,
              semister: {
                ...state.semister.semister,
                results: state.semister.semister.results.filter(
                  (r) => r.id !== payload
                ),
              },
            }
          : null,
        loading: false,
      };
    case GET_STUDENT_MARK:
      return {
        ...state,
        student_mark: payload,
        loading: false,
      };
    case GET_BATCH_STUDENTS:
      return {
        ...state,
        students: payload,
        loading: false,
      };
    case GET_MARK_DETAILS:
      return {
        ...state,
        selected_mark: payload,
        loading: false,
      };

    case DELETE_MARK:
      return {
        ...state,
        mark: state.mark.filter((grd) => grd.id !== parseInt(payload)),
        loading: false,
      };

    case UPDATE_MARK:
      return {
        ...state,
        mark: state.mark
          ? state.mark.map((grd) => (grd.id === payload.id ? payload : grd))
          : null,
        loading: false,
      };

    default:
      return state;
  }
};

export default markReducer;
