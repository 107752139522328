import React, { useEffect, useRef } from "react";
import { Container, Table } from "react-bootstrap";
import { AiFillPrinter } from "react-icons/ai";
import Moment from "react-moment";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getGradeList } from "../../actions/Grade.action";
import { months } from "../../constants/MonthsAndYears";
import { BASE_URL } from "../../constants/URL";
import { getGrade } from "../../utils/getGrade";
import { getMark } from "../../utils/getMark";
import getMonthsForTerm from "../../utils/getMonthsForTerm";
import SectionHeading from "../SemisterResultPrint/SectionHeading/SectionHeading";
import ActivitiesList from "./ActivitiesList";
import styles from "./SemisterResultView.module.css";

import logo from "../../Assets/logo.jpeg";

const SemisterResultView = ({ data, grade, getGradeList }) => {
  useEffect(() => {
    if (!grade) {
      getGradeList();
    }
  }, []);
  const ref = useRef(null);

  const avgPerformance = (subject) => {
    let count = 0;
    let total = 0;
    data.marks
      .filter((m) => m.subjectId === subject.id)
      .map((item) => {
        total += item.percent;
        count++;
      });

    if (count === 0) return 0;

    return getMark((total * subMarkNormalizer(subject)) / count);
  };
  const workingDay = (id) => {
    let total = 0;
    data.marks
      .filter((m) => m.subjectId === id)
      .map((item) => {
        total += item.working_day;
      });

    return total;
  };
  const attendence = (id) => {
    let total = 0;
    data.marks
      .filter((m) => m.subjectId === id)
      .map((item) => {
        total += item.present;
      });

    return total;
  };
  const classPerformancePercent = (subject) => {
    let total = 0;
    let count = 0;

    data.marks
      .filter((m) => m.subjectId === subject.id)
      .map((item) => {
        total += item.percent;
        count++;
      });

    return getMark((total * 0.4 * subMarkNormalizer(subject)) / count);
  };
  const semisterMark = (subject) => {
    let total = 0;

    if (
      data.semister[0].results.filter((m) => m.subjectId === subject.id)
        .length > 0
    ) {
      let list = data.semister[0].results
        .sort((a, b) => parseInt(b.id) - parseInt(a.id))
        .filter((m) => m.subjectId === subject.id);
      total += list[0].mark;
    }

    return getMark(total);
  };

  const attendencePercent = (id) => {
    if (attendence(id) === 0 || workingDay(id) === 0) {
      return 0;
    } else {
      return (attendence(id) * 100) / workingDay(id);
    }
  };

  const subMarkNormalizer = (subject) => {
    return subject.mark / 100;
  };

  const getFullTotal = () => {
    let total = 0;
    grade
      .filter((g) => g.id === parseInt(data.gradeId))[0]
      .subjects.sort(function (x, y) {
        return parseInt(x.id) - parseInt(y.id);
      })
      .map((subject) => {
        total += parseFloat(
          getMark(
            attendencePercent(subject.id) * subMarkNormalizer(subject) * 0.1 +
              ((semisterMark(subject) * subMarkNormalizer(subject) * 100) /
                subject.mark) *
                0.5 +
              classPerformancePercent(subject) * 1.0
          )
        );
      });
    return getMark(total);
  };

  const getTotalSubjectMarks = () => {
    let total = 0;
    grade &&
      grade
        .filter((g) => g.id === parseInt(data.gradeId))[0]
        .subjects.sort(function (x, y) {
          return parseInt(x.id) - parseInt(y.id);
        })
        .map((r) => {
          total += parseFloat(r.mark);
        });
    return getMark(total);
  };
  return (
    <div className="card card-body shadow bg-white">
      <div className={`${styles.wrapper} d-flex justify-content-end `}>
        <div className="">
          <ReactToPrint
            documentTitle={`${data.term} Marksheet`}
            content={() => ref.current}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <span className={styles.chart_icon} onClick={handlePrint}>
                  <AiFillPrinter />
                </span>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      </div>
      {data && data.semister && data.semister.length > 0 ? (
        <div ref={ref} className="w-100">
          {data?.student ? (
            <div className="position-relative">
              {data?.student?.image ? (
                <>
                  <img src={logo} alt="logo" className={styles.logo} />
                  <div
                    className={styles.logo2}
                    style={{
                      background: `url(${BASE_URL}/api/uploads/${data?.student?.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                  ></div>
                </>
              ) : (
                <></>
              )}
              <SectionHeading
                noLogo={data?.student?.image}
                title={`${data?.term} Result Analysis Part - ${data?.semister[0]?.year}`}
                subtitle={`Student Information`}
                grade={data?.student?.gradeId}
                shift={data?.student?.shiftId}
                batch={data?.student?.batchId}
              />

              <Container fluid className="py-4">
                <div className={styles.std_info}>
                  <div className={styles.bordr_right}>
                    <div>
                      <div className="d-flex justify-content-center align-items-center ">
                        <span className={styles.info_heading}>Name : </span>

                        <span className={styles.info_text + " ms-1"}>
                          {data?.student.name}
                        </span>
                      </div>
                    </div>
                    {data?.student.address ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>
                            Address :{" "}
                          </span>

                          <span className={styles.info_text + " ms-1"}>
                            {data?.student.address}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {data?.student.blood ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>
                            Blood Group :{" "}
                          </span>

                          <span className={styles.info_text + " ms-1"}>
                            {data?.student.blood}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={styles.bordr_right}>
                    {data?.student.father ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Father : </span>

                          <span className={styles.info_text + " ms-1"}>
                            {data?.student.father}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {data?.student.dob ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>
                            Date of Birth :{" "}
                          </span>

                          <span className={styles.info_text + " ms-1"}>
                            <Moment format="DD MMMM YYYY">
                              {data?.student.dob}
                            </Moment>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div>
                      <div className="d-flex justify-content-center fw-bold align-items-center ">
                        <span className={styles.info_heading}>Roll : </span>

                        <span className={styles.info_text + " ms-1"}>
                          {data?.student.uid}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.bordr_right}>
                    {data?.student.mother ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Mother : </span>

                          <span className={styles.info_text + " ms-1"}>
                            {data?.student.mother}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {data?.student.phone ? (
                      <div>
                        <div className="d-flex justify-content-center align-items-center ">
                          <span className={styles.info_heading}>Phone : </span>

                          <span className={styles.info_text + " ms-1"}>
                            {" "}
                            {data?.student.phone}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Container>
            </div>
          ) : (
            <></>
          )}
          <Table
            striped
            bordered
            hover
            responsive
            className={styles.table + " w-100"}
          >
            <thead>
              <tr>
                <th rowSpan="4" className="text-center">
                  Subject
                </th>
                <th rowSpan="4" className="text-center">
                  Full Marks
                </th>
                <th colSpan={12} className="text-center">
                  {data.term} Marks
                </th>
              </tr>
              <tr>
                <th scope="col" colSpan={7} className="text-center">
                  Earned Numbers
                </th>
                <th scope="col" colSpan={5} className="text-center">
                  Subjective Result
                </th>
              </tr>
              <tr>
                <th scope="col" colSpan={3} className="text-center">
                  Monthly Class Performance
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Subjectwise Semister Mark
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Average Class Performance
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Working Day
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Attendence
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Class Performance (40%)
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Exam Performance (50%)
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Attendence (10%)
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Total Marks
                </th>
                <th scope="col" rowSpan={2} className="text-center">
                  Grade
                </th>
              </tr>
              <tr>
                {" "}
                <th scope="col" className="text-center">
                  {months[getMonthsForTerm(data.term)[0]]}
                </th>
                <th scope="col" className="text-center">
                  {months[getMonthsForTerm(data.term)[1]]}
                </th>
                <th scope="col" className="text-center">
                  {months[getMonthsForTerm(data.term)[2]]}
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                grade &&
                grade
                  .filter((g) => g.id === parseInt(data.gradeId))[0]
                  .subjects.sort(function (x, y) {
                    return parseInt(x.id) - parseInt(y.id);
                  })
                  .map((subject) => (
                    <tr key={subject.id}>
                      <td>{subject.name}</td>
                      <td>{subject.mark}</td>
                      <td>
                        {data.marks.filter(
                          (m) =>
                            m.month === getMonthsForTerm(data.term)[0] &&
                            m.subjectId === subject.id
                        )[0]?.percent
                          ? getMark(
                              (
                                data.marks
                                  .filter(
                                    (m) =>
                                      m.month ===
                                        getMonthsForTerm(data.term)[0] &&
                                      m.subjectId === subject.id
                                  )[0]
                                  ?.percent.toFixed(2) *
                                subMarkNormalizer(subject)
                              ).toFixed(2)
                            )
                          : ""}
                      </td>
                      <td>
                        {data.marks.filter(
                          (m) =>
                            m.month === getMonthsForTerm(data.term)[1] &&
                            m.subjectId === subject.id
                        )[0]?.percent
                          ? getMark(
                              (
                                data.marks
                                  .filter(
                                    (m) =>
                                      m.month ===
                                        getMonthsForTerm(data.term)[1] &&
                                      m.subjectId === subject.id
                                  )[0]
                                  ?.percent.toFixed(2) *
                                subMarkNormalizer(subject)
                              ).toFixed(2)
                            )
                          : ""}
                      </td>
                      <td>
                        {data.marks.filter(
                          (m) =>
                            m.month === getMonthsForTerm(data.term)[2] &&
                            m.subjectId === subject.id
                        )[0]?.percent
                          ? getMark(
                              (
                                data.marks
                                  .filter(
                                    (m) =>
                                      m.month ===
                                        getMonthsForTerm(data.term)[2] &&
                                      m.subjectId === subject.id
                                  )[0]
                                  ?.percent.toFixed(2) *
                                subMarkNormalizer(subject)
                              ).toFixed(2)
                            )
                          : ""}
                      </td>
                      <td>{semisterMark(subject)}</td>
                      <td>{avgPerformance(subject)}</td>
                      <td>{workingDay(subject.id)}</td>
                      <td>{attendence(subject.id)}</td>
                      <td>{classPerformancePercent(subject)}</td>
                      <td>{semisterMark(subject) * 0.5}</td>
                      <td>
                        {getMark(
                          parseFloat(
                            attendencePercent(subject.id) *
                              0.1 *
                              subMarkNormalizer(subject)
                          )
                        )}
                      </td>
                      <td>
                        {getMark(
                          attendencePercent(subject.id) *
                            subMarkNormalizer(subject) *
                            0.1 +
                            ((semisterMark(subject) *
                              subMarkNormalizer(subject) *
                              100) /
                              subject.mark) *
                              0.5 +
                            classPerformancePercent(subject) * 1.0
                        )}
                      </td>
                      <td>
                        {getGrade(
                          getMark(
                            (attendencePercent(subject.id) *
                              subMarkNormalizer(subject) *
                              0.1 +
                              ((semisterMark(subject) *
                                subMarkNormalizer(subject) *
                                100) /
                                subject.mark) *
                                0.5 +
                              classPerformancePercent(subject) * 1.0) *
                              (100 / subject.mark)
                          )
                        )}
                      </td>
                    </tr>
                  ))}

              <tr>
                <td colSpan={14} rowSpan={2}>
                  {/* <span className="d-block fw-bold pt-3">
                    Extra Curricular Activities
                  </span>
                  <ActivitiesList gradeId={data?.semister[0].gradeId} /> */}
                  <div
                    className={`d-flex justify-content-center align-items-center py-4 ${styles.overall_info}`}
                  >
                    <span className="d-block">
                      <b>Total Mark = {getTotalSubjectMarks()}</b>
                    </span>
                    <span className="d-block ps-4">
                      <b>Total Gained Mark = {getMark(getFullTotal())}</b>
                    </span>
                    <span className="d-block ps-4">
                      <b>
                        {" "}
                        Gained Percent ={" "}
                        {getMark(
                          (getFullTotal() * 100) / getTotalSubjectMarks()
                        )}
                        %
                      </b>
                    </span>
                    <span className="d-block ps-4">
                      <b>
                        Overall Grade ={" "}
                        {getGrade(
                          getMark(
                            (getFullTotal() * 100) / getTotalSubjectMarks()
                          )
                        )}
                      </b>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        <>
          <h3 className="text-center py-4">No semister result found</h3>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  grade: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(SemisterResultView);
