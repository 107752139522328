import React from "react";
import { RatioGraph } from "../../components/RatioGraph";
import Layout from "../../components/shared/Layout/Layout";

const CompariosnGraph = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Comparison Graph`}>
        <RatioGraph />
      </Layout>
    </div>
  );
};

export default CompariosnGraph;
