import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_SEMISTER,
  DELETE_SEMISTER,
  GET_SEMISTER,
  GET_GRADE_SEMISTER,
  UPDATE_SEMISTER,
  GET_STUDENT_SEMISTER,
  GET_STUDENT_TERM_RESULT,
  GET_SEMISTER_DETAILS,
  GET_TOP_SEMISTER,
  GET_FULL_SEMISTER,
  GET_FULL_SEMISTER_TOP,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET MARK LIST
export const getSemisterList =
  (batchId, year, month, gradeId, shiftId, subjectId) => async (dispatch) => {
    const data = {
      batchId,
      year,
      month,
      gradeId,
      shiftId,
      subjectId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/mark/batch`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_SEMISTER,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
// TOP SEEMISTER LIST
export const getTopSemisterResult =
  (batchId, year, term, gradeId, shiftId) => async (dispatch) => {
    const data = {
      batchId,
      year,
      term,
      gradeId,
      shiftId,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(
        `${BASE_URL}/api/semister/top-result`,
        JSON.stringify(data),
        config
      );

      dispatch({
        type: GET_TOP_SEMISTER,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };

//GET GRADE MARK LIST
export const getSemisterListForPerformanceGrade =
  (year, month, gradeId, shiftId, batchId, subjectId) => async (dispatch) => {
    const data = {
      year,
      month,
      gradeId,
      shiftId,
      batchId,
      subjectId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/mark/grade`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_SEMISTER,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
export const getFullTermResult =
  (year, gradeId, shiftId, batchId, uid, term) => async (dispatch) => {
    const data = {
      year,
      gradeId,
      shiftId,
      batchId,
      uid,
      term,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/semister/full`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_STUDENT_TERM_RESULT,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
export const getFullFinalResult =
  (year, gradeId, shiftId, batchId, uid) => async (dispatch) => {
    const data = {
      year,
      gradeId,
      shiftId,
      batchId,
      uid,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/semister/final`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_FULL_SEMISTER,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
export const getFullFinalTopResult =
  (year, gradeId, shiftId, batchId) => async (dispatch) => {
    const data = {
      year,
      gradeId,
      shiftId,
      batchId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/semister/final-top`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_FULL_SEMISTER_TOP,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
export const getSemisterDetails =
  (year, gradeId, shiftId, batchId, term, subjectId) => async (dispatch) => {
    const data = {
      year,
      gradeId,
      shiftId,
      batchId,
      term,
      subjectId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/semister/details`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_SEMISTER_DETAILS,
        payload: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

// CREATE MARK
export const createSemister =
  (month, year, batchId, students, grade, shift, subjectId) =>
  async (dispatch) => {
    let formData = {
      term: month,
      year,
      batchId,
      results: [],
      shiftId: shift,
      gradeId: grade,
    };

    for (let i = 0; i < students.length; i++) {
      formData.results.push({
        studentId: students[i].studentId,
        subjectId,
        mark: students[i].total,
      });
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/semister`,
        JSON.stringify(formData),
        config
      );
      dispatch({
        type: CREATE_SEMISTER,
        payload: res.data.data,
      });
      toast.success("Semister added successfully");

      return true;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };

// UPDATE MARK
export const updateSemister = (mark, id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/semister/${id}`,
      JSON.stringify({ mark }),
      config
    );
    dispatch({
      type: UPDATE_SEMISTER,
      payload: res.data.data,
    });

    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
//DELETE Result
export const deleteResult = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/semister/${id}`);
    dispatch({
      type: DELETE_SEMISTER,
      payload: id,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
