import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import TopSemsterResult from "../../components/TopSemsterResult/TopSemsterResult";

const TopSemisterResultPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Top Semister Result`}>
        <TopSemsterResult />
      </Layout>
    </div>
  );
};

export default TopSemisterResultPage;
