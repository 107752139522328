import React from "react";
import { AcademicGrapg } from "../../components/AcademicGrapg";
import Layout from "../../components/shared/Layout/Layout";

const AcademicGraphPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Academic Graph`}>
        <AcademicGrapg />
      </Layout>
    </div>
  );
};

export default AcademicGraphPage;
