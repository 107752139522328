import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  GET_SAVED,
  GET_SAVED_DETAILS,
  UPDATE_SAVED,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET Saved LIST
export const getSavedList = (semisterId, studentId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${BASE_URL}/api/saved`,
      JSON.stringify({ semisterId, studentId }),
      config
    );

    dispatch({
      type: GET_SAVED,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: GET_SAVED,
      payload: null,
    });
  }
};

// CREATE Saved
export const updateSaved =
  (semisterId, studentId, values) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // TODO ::: API CALL
      const res = await axios.patch(
        `${BASE_URL}/api/saved`,
        JSON.stringify({ semisterId, studentId, data: values }),
        config
      );
      dispatch({
        type: UPDATE_SAVED,
        payload: res.data.data,
      });
      toast.success("Activity updated successfully");

      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };
