import {
  CREATE_SHIFT,
  DELETE_SHIFT,
  GET_SHIFT,
  GET_SHIFT_DETAILS,
  UPDATE_SHIFT,
} from "../constants/Type";

const initialState = {
  shift: null,
  selected_shift: null,
  loading: true,
};

const shiftReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SHIFT:
      return {
        ...state,
        shift: payload,
        loading: false,
      };
    case GET_SHIFT_DETAILS:
      return {
        ...state,
        selected_shift: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default shiftReducer;
