import React from "react";

const MarkDistribution = () => {
  return (
    <div className="d-flex align-items center justify-content-center pb-5 pt-4">
      <div className="d-flex justify-content-center">
        <span className="d-block pe-3">
          <span className="fw-bold">A++</span> = (91-100)
        </span>
        <span className="d-block pe-3">
          <span className="fw-bold">A+</span> = (81-90)
        </span>
        <span className="d-block pe-3">
          <span className="fw-bold">A</span> = (71-80)
        </span>
        <span className="d-block pe-3">
          <span className="fw-bold">A-</span> = (61-70)
        </span>
        <span className="d-block pe-3">
          <span className="fw-bold">B</span> = (51-60)
        </span>
        <span className="d-block pe-3">
          <span className="fw-bold">C</span> = {"(<51)"}{" "}
        </span>
      </div>
    </div>
  );
};

export default MarkDistribution;
