import React from "react";
import MarkList from "../../components/MarkList/MarkList";
import Layout from "../../components/shared/Layout/Layout";
import { TopMarkList } from "../../components/TopMarkList";

const MarkListPage = ({ top }) => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`${top ? "Top " : ""} Result`}>
        {top === true ? <MarkList /> : <TopMarkList />}
      </Layout>
    </div>
  );
};

export default MarkListPage;
