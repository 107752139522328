import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import ViewBatchSubWiseAttendence from "../../components/ViewBatchSubWiseAttendence/ViewBatchSubWiseAttendence";

const AttandanceBatchPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Attendence - Batch`}>
        <ViewBatchSubWiseAttendence />
      </Layout>
    </div>
  );
};

export default AttandanceBatchPage;
