import { Accordion } from "@mantine/core";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineEdit, AiOutlineHome, AiOutlineTags } from "react-icons/ai";
import { BiUser, BiUserCheck, BiUserPin } from "react-icons/bi";
import {
  BsCalendar2Check,
  BsCalendarPlus,
  BsCalendarWeek,
  BsFileEarmarkMusic,
  BsFillDiagram3Fill,
  BsFillFileEarmarkBarGraphFill,
  BsFillFileEarmarkSpreadsheetFill,
  BsListNested,
  BsStack,
} from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { GoThreeBars } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdAddChart, MdHotelClass } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { connect } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../actions/Dashboard.action";
import styles from "./Layout.module.css";

const Layout = ({ logout, children, title }) => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState("");

  const logoutHandeler = async () => {
    let check = await logout();
    if (check === true) {
      navigate("/");
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="position-relative">
          <Col
            md={2}
            className={`px-4 ${styles.wrapper} ${show ? styles.active : ""}`}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <Link
                to="/dashboard"
                className="d-flex align-items-center py-3 text-decoration-none text-dark"
              >
                {/* <img src={logo} alt="" className={styles.logo} /> */}
              </Link>
              <div
                className={`${styles.ham}  ms-auto`}
                onClick={() => setShow(!show)}
              >
                <GoThreeBars />
              </div>
            </div>

            <div className={styles.nav}>
              <NavLink to="/dashboard" className={styles.nav__item}>
                <span className={styles.icon}>
                  <AiOutlineHome />
                </span>
                <span className={styles.nav__item_text}>Dashboard</span>
              </NavLink>
            </div>

            <div className="">
              <Accordion multiple value={type} onChange={setType}>
                <Accordion.Item value="operation">
                  <Accordion.Control>Manage</Accordion.Control>
                  <Accordion.Panel>
                    <div className={styles.nav}>
                      <NavLink to="/grade" className={styles.nav__item}>
                        <span className={styles.icon}>
                          <BsFillDiagram3Fill />
                        </span>
                        <span className={styles.nav__item_text}>Class</span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink to="/admin/shift" className={styles.nav__item}>
                        <span className={styles.icon}>
                          <BsStack />
                        </span>
                        <span className={styles.nav__item_text}>Shift</span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink to="/admin/batch" className={styles.nav__item}>
                        <span className={styles.icon}>
                          <HiOutlineUserGroup />
                        </span>
                        <span className={styles.nav__item_text}>Batch</span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink to="/admin/subject" className={styles.nav__item}>
                        <span className={styles.icon}>
                          <AiOutlineTags />
                        </span>
                        <span className={styles.nav__item_text}>Subject</span>
                      </NavLink>
                    </div>

                    <div className={styles.nav}>
                      <NavLink to="/admin/student" className={styles.nav__item}>
                        <span className={styles.icon}>
                          <BiUser />
                        </span>
                        <span className={styles.nav__item_text}>Student</span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink to="/admin/extra" className={styles.nav__item}>
                        <span className={styles.icon}>
                          <BsFileEarmarkMusic />
                        </span>
                        <span className={styles.nav__item_text}>
                          Extra Curricular Activities
                        </span>
                      </NavLink>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="semister">
                  <Accordion.Control>Semister</Accordion.Control>
                  <Accordion.Panel>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/semister-add"
                        end={true}
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsCalendarPlus />
                        </span>
                        <span className={styles.nav__item_text}>
                          Add Semister Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/semister-edit"
                        end={true}
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsCalendarWeek />
                        </span>
                        <span className={styles.nav__item_text}>
                          Edit Semister Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/semister"
                        end={true}
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsCalendar2Check />
                        </span>
                        <span className={styles.nav__item_text}>
                          View Semister Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/semister-print"
                        end={true}
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsCalendar2Check />
                        </span>
                        <span className={styles.nav__item_text}>
                          Print Semister Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/semister-top"
                        end={true}
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsCalendar2Check />
                        </span>
                        <span className={styles.nav__item_text}>
                          Top Semister Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/semister-full"
                        end={true}
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsCalendar2Check />
                        </span>
                        <span className={styles.nav__item_text}>
                          Full Semister Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/semister-full-top"
                        end={true}
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsCalendar2Check />
                        </span>
                        <span className={styles.nav__item_text}>
                          Full Semister Top Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/semister-summary"
                        end={true}
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsCalendar2Check />
                        </span>
                        <span className={styles.nav__item_text}>
                          Semister Summary
                        </span>
                      </NavLink>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="monthly">
                  <Accordion.Control>Monthly</Accordion.Control>
                  <Accordion.Panel>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/mark/add"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <MdAddChart />
                        </span>
                        <span className={styles.nav__item_text}>
                          Add Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        to="/admin/mark/edit"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <AiOutlineEdit />
                        </span>
                        <span className={styles.nav__item_text}>
                          Edit Result
                        </span>
                      </NavLink>
                    </div>

                    <div className={styles.nav}>
                      <NavLink
                        end
                        to="/admin/view-result"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsFillFileEarmarkBarGraphFill />
                        </span>
                        <span className={styles.nav__item_text}>
                          View Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        end
                        to="/admin/attendence-batch"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsFillFileEarmarkSpreadsheetFill />
                        </span>
                        <span className={styles.nav__item_text}>
                          Monthly Attendence
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        end
                        to="/admin/overview-batch"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BsListNested />
                        </span>
                        <span className={styles.nav__item_text}>
                          Top Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        end
                        to="/admin/individual-result"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BiUserPin />
                        </span>
                        <span className={styles.nav__item_text}>
                          Individual Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        end
                        to="/admin/individual-result-ind"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <BiUserCheck />
                        </span>
                        <span className={styles.nav__item_text}>
                          Monthly Basis Serial Result
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        end
                        to="/admin/class-performance"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <SiGoogleclassroom />
                        </span>
                        <span className={styles.nav__item_text}>
                          Class Performance
                        </span>
                      </NavLink>
                    </div>
                    <div className={styles.nav}>
                      <NavLink
                        end
                        to="/admin/class-summary"
                        className={styles.nav__item}
                      >
                        <span className={styles.icon}>
                          <MdHotelClass />
                        </span>
                        <span className={styles.nav__item_text}>
                          Class Summary
                        </span>
                      </NavLink>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="d-none">
              <div className={styles.nav}>
                <NavLink
                  to="/admin/semister-add"
                  end={true}
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BsCalendarPlus />
                  </span>
                  <span className={styles.nav__item_text}>
                    Add Semister Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  to="/admin/semister-edit"
                  end={true}
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BsCalendarWeek />
                  </span>
                  <span className={styles.nav__item_text}>
                    Edit Semister Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  to="/admin/semister"
                  end={true}
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BsCalendar2Check />
                  </span>
                  <span className={styles.nav__item_text}>
                    View Semister Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  to="/admin/semister-top"
                  end={true}
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BsCalendar2Check />
                  </span>
                  <span className={styles.nav__item_text}>
                    Top Semister Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  to="/admin/semister-full"
                  end={true}
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BsCalendar2Check />
                  </span>
                  <span className={styles.nav__item_text}>
                    Full Semister Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink to="/admin/mark/add" className={styles.nav__item}>
                  <span className={styles.icon}>
                    <MdAddChart />
                  </span>
                  <span className={styles.nav__item_text}>
                    Add Monthly Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink to="/admin/mark/edit" className={styles.nav__item}>
                  <span className={styles.icon}>
                    <AiOutlineEdit />
                  </span>
                  <span className={styles.nav__item_text}>
                    Edit Monthly Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  end
                  to="/admin/individual-result"
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BiUserPin />
                  </span>
                  <span className={styles.nav__item_text}>
                    Individual Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  end
                  to="/admin/individual-result?route=individual"
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BiUserCheck />
                  </span>
                  <span className={styles.nav__item_text}>
                    Monthly Basis Serial Result
                  </span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  end
                  to="/admin/class-performance"
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <SiGoogleclassroom />
                  </span>
                  <span className={styles.nav__item_text}>
                    Class Performance
                  </span>
                </NavLink>
              </div>

              <div className={styles.nav}>
                <NavLink
                  end
                  to="/admin/class-summary"
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <MdHotelClass />
                  </span>
                  <span className={styles.nav__item_text}>Class Summary</span>
                </NavLink>
              </div>
              {/* <div className={styles.nav}>
              <NavLink end to="/admin/mark" className={styles.nav__item}>
                <span className={styles.icon}>
                  <AiOutlinePrinter />
                </span>
                <span className={styles.nav__item_text}>Print Result</span>
              </NavLink>
            </div> */}
              <div className={styles.nav}>
                <NavLink
                  end
                  to="/admin/view-result"
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BsFillFileEarmarkBarGraphFill />
                  </span>
                  <span className={styles.nav__item_text}>View Result</span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  end
                  to="/admin/overview-batch"
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BsListNested />
                  </span>
                  <span className={styles.nav__item_text}>Top Result</span>
                </NavLink>
              </div>
              <div className={styles.nav}>
                <NavLink
                  end
                  to="/admin/attendence-batch"
                  className={styles.nav__item}
                >
                  <span className={styles.icon}>
                    <BsFillFileEarmarkSpreadsheetFill />
                  </span>
                  <span className={styles.nav__item_text}>
                    Attendence Batch
                  </span>
                </NavLink>
              </div>
            </div>
            {/* <div className={styles.nav}>
              <NavLink
                end
                to="/admin/alarming-student"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <MdOutlineSdCardAlert />
                </span>
                <span className={styles.nav__item_text}>Alarming Student</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink end to="/admin/mark/top" className={styles.nav__item}>
                <span className={styles.icon}>
                  <AiOutlineStar />
                </span>
                <span className={styles.nav__item_text}>Top Result</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink end to="/admin/comparison" className={styles.nav__item}>
                <span className={styles.icon}>
                  <MdOutlineCompareArrows />
                </span>
                <span className={styles.nav__item_text}>Comparison</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                end
                to="/admin/academic-graph"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <BsGraphUp />
                </span>
                <span className={styles.nav__item_text}>Academic Graph</span>
              </NavLink>
            </div> */}

            <div className={styles.nav}>
              <div className={styles.nav__item} onClick={logoutHandeler}>
                <span className={styles.icon}>
                  <FiLogOut />
                </span>
                <span className={styles.nav__item_text}>Logout</span>
              </div>
            </div>
          </Col>
          <Col md={10} className="bg-light">
            <div className="d-flex justify-content-end align-items-center py-4">
              <div
                className={`${styles.ham}  me-auto`}
                onClick={() => setShow(!show)}
              >
                <GoThreeBars />
              </div>
              {/* <div className="pb-3">
                  <img src={logo} style={{ height: 40 }} alt="" />
                </div> */}
              {title ? (
                <h3 className=" mx-auto fs-3 my-auto py-4">{title}</h3>
              ) : (
                <></>
              )}

              {/* <UserInfoTopbar /> */}
            </div>
            <Container>{children}</Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connect(null, { logout })(Layout);
