import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import styles from "./ViewResults.module.css";
import { connect } from "react-redux";
import { AiFillPrinter } from "react-icons/ai";
import { deleteGrade, getGradeList } from "../../actions/Grade.action";
import { months, years } from "../../constants/MonthsAndYears";
import { getMarkList } from "../../actions/Mark.action";
import { getGrade } from "../../utils/getGrade";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Link } from "react-router-dom";
import { getMark } from "../../utils/getMark";
import SectionHeading from "../SectionHeading/SectionHeading";
import GradeCount from "../GradeCount/GradeCount";
import MarkDistribution from "../MarkDistribution/MarkDistribution";

const ViewResults = ({ list, getGradeList, extra, grades, getMarkList }) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [subject, setSubject] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("0");
  const [subjectList, setSubjectList] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    if (!grades) {
      getGradeList();
    }

    if (list !== null) {
      setRawList(list);
      let tempSubs = [];
      list.map((item) => {
        if (!tempSubs.includes(item.subject.name)) {
          tempSubs.push(item.subject.name);
        }
        return item;
      });

      setSubjectList([...tempSubs]);
    }
  }, [list]);

  const selectHandeler = async () => {
    await getMarkList(batch, year, month, grade, shift, subject);
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grades && grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" ? (
              <Col
                md={3}
                className="d-md-flex jsutify-content-end align-items-end py-3 d-none"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            {grade !== "" ? (
              <Col md={4} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="subject" className="d-block">
                    Subject
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                >
                  <option value={""}>Select Subject</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .subjects.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="month" className="d-block">
                  Month
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                id="month"
                name="month"
              >
                {months.map((item, i) => (
                  <option key={i} value={`${i}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                value={year}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col
                md={3}
                className="d-md-none jsutify-content-end align-items-end py-3 d-flex"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Card.Body>
      </Card>
      {rawList !== null ? (
        <Card className="crd p-md-2 pb-md-0 p-2 mt-4">
          <Card.Body>
            {rawList === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : rawList.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <>
                <div className="d-flex justify-content-end ">
                  <div className="">
                    <ReactToPrint
                      documentTitle={`Marksheet - ${rawList[0].batch.name} - ${
                        months[rawList[0].month]
                      } - ${rawList[0].year}`}
                      content={() => ref.current}
                    >
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <span
                            className={styles.chart_icon}
                            onClick={handlePrint}
                          >
                            <AiFillPrinter />
                          </span>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                  </div>
                </div>
                <div ref={ref}>
                  {subjectList.map((selectedSub, i) => (
                    <Fragment key={i}>
                      <div className="p-3">
                        <SectionHeading
                          title={`Monthly Subject-wise Class Performance Report - ${selectedSub}`}
                          year={rawList[0].year}
                          month={months[rawList[0].month]}
                          grade={extra?.gradeId}
                          shift={extra?.shiftId}
                          batch={extra?.batchId}
                        />

                        <MarkDistribution />
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Roll</th>
                              <th>Name</th>
                              <th>Percent</th>
                              <th>Grade</th>
                              <th>Position</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* SORT FUNCTION FOR SORT BY ROLL 
                            .sort(function (a, b) {
                                  if (a.student.uid < b.student.uid) return -1;
                                  if (a.student.uid > b.student.uid) return 1;
                                  return 0;
                                }) */}
                            {rawList !== null &&
                              rawList
                                .filter((l) => l.subject.name === selectedSub)
                                .sort(function (a, b) {
                                  if (a.percent === b.percent) {
                                    return parseInt(a.student.uid) >
                                      parseInt(b.student.uid)
                                      ? 1
                                      : -1;
                                  }
                                  if (a.percent < b.percent) return 1;
                                  else return -1;
                                })
                                .map((markItem, i) => (
                                  <tr key={markItem.id}>
                                    <td>{markItem.student.uid}</td>
                                    <td>
                                      <Link
                                        to={`/admin/mark/student/${markItem.student.id}`}
                                        className="link-custom"
                                      >
                                        {markItem.student.name}{" "}
                                      </Link>
                                    </td>
                                    {/* <td>{markItem.total}</td> */}
                                    {/* <td>{markItem.working_day}</td>
                                    <td>{markItem.present}</td> */}
                                    <td>{getMark(markItem.percent)}%</td>
                                    <td>{getGrade(markItem.percent)}</td>
                                    <td>
                                      {[...rawList]
                                        .filter(
                                          (l) => l.subject.name === selectedSub
                                        )
                                        .sort(function (a, b) {
                                          if (a.percent === b.percent) {
                                            return parseInt(a.student.uid) >
                                              parseInt(b.student.uid)
                                              ? 1
                                              : -1;
                                          }
                                          if (a.percent < b.percent) return 1;
                                          else return -1;
                                        })
                                        .map((l) => l.id)
                                        .indexOf(markItem.id) + 1}
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </Table>
                        <div className="d-flex align-items center justify-content-center">
                          <div className="d-flex">
                            <span className="d-block pe-3">
                              <span className="fw-bold">A++</span> ={" "}
                              {
                                rawList
                                  .filter((l) => l.subject.name === selectedSub)
                                  .filter((l) => getGrade(l.percent) === "A++")
                                  .length
                              }
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">A+</span> ={" "}
                              {
                                rawList
                                  .filter((l) => l.subject.name === selectedSub)
                                  .filter((l) => getGrade(l.percent) === "A+")
                                  .length
                              }
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">A</span> ={" "}
                              {
                                rawList
                                  .filter((l) => l.subject.name === selectedSub)
                                  .filter((l) => getGrade(l.percent) === "A")
                                  .length
                              }
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">A-</span> ={" "}
                              {
                                rawList
                                  .filter((l) => l.subject.name === selectedSub)
                                  .filter((l) => getGrade(l.percent) === "A-")
                                  .length
                              }
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">B</span> ={" "}
                              {
                                rawList
                                  .filter((l) => l.subject.name === selectedSub)
                                  .filter((l) => getGrade(l.percent) === "B")
                                  .length
                              }
                            </span>
                            <span className="d-block pe-3">
                              <span className="fw-bold">C</span> ={" "}
                              {
                                rawList
                                  .filter((l) => l.subject.name === selectedSub)
                                  .filter((l) => getGrade(l.percent) === "C")
                                  .length
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.mark.mark,
  extra: state.mark.extra,
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  getGradeList,
  deleteGrade,
  getMarkList,
})(ViewResults);
