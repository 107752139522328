export const getMark = (mark) => {
  mark = parseFloat(mark);
  if (isNaN(mark)) {
    return 0.0;
  }

  if ((mark * 100) % 100 === 0) {
    return mark.toFixed(0);
  }
  return (Math.round(mark * 100) / 100).toFixed(2);
};
