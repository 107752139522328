import React, { createRef, Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { BiTrash } from "react-icons/bi";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createSemister,
  getSemisterDetails,
} from "../../actions/Semister.action";
import { getSubjectDetails } from "../../actions/Subject.action";
import { years } from "../../constants/MonthsAndYears";
import styles from "./EditSemisterForm.module.css";
import OneResultRow from "./OneResultRow/OneResultRow";

const EditSemisterForm = ({
  grades,
  getSemisterDetails,
  sub,
  data,
  getSubjectDetails,
}) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [subject, setSubject] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("1st Term");
  const [showToast, setShowToast] = useState(true);

  const [elRefs, setElRefs] = useState([]);
  const [stdList, setStdList] = useState([]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(data?.semister?.results?.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );

    if (data?.semister?.results) {
      let stdListTmp = [];
      data?.semister?.results
        .sort((a, b) => {
          if (parseInt(a.student.uid) === parseInt(b.student.uid)) {
            return parseInt(b.id) - parseInt(a.id);
          }
          return parseInt(a.student.uid) - parseInt(b.student.uid);
        })
        .map((res) => {
          if (
            stdListTmp.filter((item) => item.studentId === res.studentId)
              .length === 0
          ) {
            stdListTmp.push({ studentId: res.studentId, id: res.id });
          }
        });
      //console.log(stdList);
      setStdList(stdListTmp);
    }
  }, [data?.semister?.results]);

  const selectHandeler = async () => {
    await getSemisterDetails(year, grade, shift, batch, month, subject);

    getSubjectDetails(subject);
  };

  const editAll = () => {
    setShowToast(false);
    setTimeout(() => {
      elRefs.map((item) => {
        if (item?.current) {
          item.current.click();
        }
      });
      toast.success(
        "All results updated! It may take couple of minutes to take effect."
      );
    }, 500);

    setTimeout(() => {
      setShowToast(true);
    }, 5000);
  };

  const checkStudent = (res) => {
    let check = false;
    // console.log(res);
    stdList.map((item) => {
      if (item.studentId === res.student.id && item.id === res.id) {
        check = true;
      }
    });
    // console.log(check);
    return check;
  };

  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            {grade !== "" && shift !== "" && batch !== "" && subject !== "" ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            {grade !== "" ? (
              <Col md={4} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="subject" className="d-block">
                    Subject
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                >
                  <option value={""}>Select Subject</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .subjects.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="month" className="d-block">
                  Term
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                id="month"
                name="month"
              >
                <option value={`1st Term`}>1st Term</option>
                <option value={`2nd Term`}>2nd Term</option>
                <option value={`3rd Term`}>3rd Term</option>
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {data ? (
        <Card bg="white" text="dark" className={`crd shadow mt-4`}>
          <Card.Body>
            <h1 className="fs-4 fw-normal py-3">
              Input marks for{" "}
              {sub ? (
                <>
                  <b>{sub.name}</b> | Max mark : <b>{sub.mark}</b>{" "}
                </>
              ) : (
                "Loading..."
              )}
            </h1>
            <Row>
              <Col
                md={1}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="d-block fw-bold">Roll</span>
              </Col>
              <Col md={5} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Name</span>
              </Col>
              <Col md={5} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Total Mark</span>
              </Col>

              <Col md={1} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Delete</span>
              </Col>
            </Row>
            <hr />
            {data && data?.semister?.results ? (
              data.semister.results
                .sort((a, b) => {
                  if (parseInt(a.student.uid) === parseInt(b.student.uid)) {
                    return parseInt(b.id) - parseInt(a.id);
                  }
                  return parseInt(a.student.uid) - parseInt(b.student.uid);
                })
                .filter((r) => {
                  return checkStudent(r);
                })
                .map((result, i) => (
                  <OneResultRow
                    showToast={showToast}
                    key={result.id}
                    result={result}
                    elRef={elRefs[i]}
                  />
                ))
            ) : (
              <></>
            )}

            <Button onClick={editAll} variant="primary" className="btn_primary">
              Update All
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  sub: state.subject.selected_subject,
  data: state.mark.semister,
});

export default connect(mapStateToProps, {
  getSemisterDetails,
  createSemister,
  getSubjectDetails,
})(EditSemisterForm);
